/* eslint-disable */
import "../assets/css/settingsScreen.css";
import isElectron from "is-electron";
import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../hooks/SettingsContext";
import { FontSizes, Layouts } from "../utils/Constants";
import { useHistory } from "react-router-dom";
import trashIcon from "./../assets/images/trashicon.png";
import { useTranslation } from "react-i18next";
import homeIcon from "./../assets/images/bar/buttons/home.webp";

/**
 * L'écran des paramètres
 * @component
 * @param props
 * @param props.setScreen {Function} - Une fonction permettant de changer l'écran actuel
 *
 */
function SettingsScreen(props) {
  const { setScreen, activeStory } = props;
  const { lang, settings, setSettings, playSound, stopSound, isPlaying } =
    useContext(SettingsContext);
  const history = useHistory();
  const [, setStories] = useState([]);

  const [playing, setPlaying] = useState(isPlaying());

  const { t } = useTranslation();

  const [defaultFont] = useState(() =>
    isElectron() ? props.location.story.font_path : activeStory.data.font_path
  );
  /**
   * Une fonction permettant de changer le type de la police
   * @method
   * @param fontType - Le type de police à utiliser dans le conte
   *
   */
  const setFontType = (fontType) => {
    const newSettings = { ...settings };
    newSettings.fontType = fontType;
    setSettings(newSettings);
  };

  /**
   * Une fonction permettant de changer la taille de la police
   * @method
   * @param fontSize - La taille de la police à utiliser dans le conte
   *
   */
  const setFontSize = (fontSize) => {
    const newSettings = { ...settings };
    newSettings.fontSize = fontSize;
    setSettings(newSettings);
  };

  const setSoundPlaying = (playing) => {
    let newSettings = { ...settings };
    if (isPlaying()) {
      newSettings.playing = false;
      setPlaying(false);
      stopSound();
    } else {
      newSettings.playing = true;
      setPlaying(true);
      playSound();
    }
    setSettings(newSettings);
  };

  useEffect(() => {
    if (isElectron()) {
      window["api"].send("getLocalStories", null);
      window["api"].receive("localStories", setStories);
    }
  }, []);

  // const resetSettings = () => {
  //   let newSettings = { fontType: 0, fontSize: FontSizes.FONT_MEDIUM, playing: true };
  //   setPlaying(true);
  //   setSettings(newSettings);
  //   if (!isPlaying()) playSound();
  //   localStorage.clear();
  // };
  // if (!isElectron()) {
  //   window.onpopstate = (e) => history.push({ pathname: "/home" });
  // }

  return (
    <div dir={lang == "ar" ? "rtl" : "ltr"} className="general-container font-tifinagh-1">
      <div className="settings-header">
        {!isElectron() ? (
          <img
            className="settings-header-a"
            onClick={() => setScreen(Layouts.BOOK)}
            src={homeIcon}
            style={{ cursor: "pointer", width: "48px", height: "48px" }}
            alt=""
          />
        ) : (
          <img
            className="settings-header-a"
            onClick={() => {
              history.push({
                pathname: isElectron() ? "/story" : "/",
                storyId: isElectron() ? props.location.story.id : "",
                story: isElectron() ? props.location.story : "",
                state: "skip",
              });
            }}
            src={homeIcon}
            style={{ cursor: "pointer", width: "48px", height: "48px" }}
            alt=""
          />
        )}
        <h1>{t("settings")}</h1>
      </div>
      <div className="settings-container">
        <div className="font-settings">
          {/* Font type */}
          <h3 className="settings-title">{t("font_settings")}</h3>
          <div className="radio-options">
            <p className={lang === "ar" ? "min-width-80" : "min-width-50"}>{t("font")}</p>
            {/* <div>
              <input
                type="radio"
                id="font-1"
                name="font-type"
                checked={settings.fontType == 0}
                onChange={() => setFontType(0)}
              />
              <label htmlFor="font-1">Adlis</label>
            </div> */}
            <div>
              <input
                type="radio"
                id="font-1"
                name="font-type"
                checked={
                  settings.fontType !== undefined ? settings.fontType == 0 : defaultFont == 0
                }
                onChange={() => setFontType(0)}
              />
              <label htmlFor="font-1" style={{ fontSize: "1.2vw" }}>
                Tifinaghe Agoug
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="font-2"
                name="font-type"
                checked={
                  settings.fontType !== undefined ? settings.fontType == 1 : defaultFont == 1
                }
                onChange={() => setFontType(1)}
              />
              <label htmlFor="font-2" style={{ fontSize: "1.2vw" }}>
                Tifinaghe Unicode
              </label>
            </div>

            <div>
              <input
                type="radio"
                id="font-3"
                name="font-type"
                checked={
                  settings.fontType !== undefined ? settings.fontType == 2 : defaultFont == 2
                }
                onChange={() => setFontType(2)}
              />
              <label htmlFor="font-3" style={{ fontSize: "1.2vw" }}>
                Maghribi
              </label>
            </div>
          </div>

          {/* Font size */}
          <div className="radio-options">
            <p className={lang === "ar" ? "min-width-80" : "min-width-50"}>{t("size")}</p>
            <div>
              <input
                type="radio"
                id="font-sm"
                name="font-size"
                checked={settings.fontSize == FontSizes.FONT_SMALL}
                onChange={() => setFontSize(FontSizes.FONT_SMALL)}
              />
              <label htmlFor="font-sm">{t("small")}</label>
            </div>
            <div>
              <input
                type="radio"
                id="font-md"
                name="font-size"
                checked={settings.fontSize == FontSizes.FONT_MEDIUM}
                onChange={() => setFontSize(FontSizes.FONT_MEDIUM)}
              />
              <label htmlFor="font-md">{t("medium")}</label>
            </div>
            <div>
              <input
                type="radio"
                id="font-lg"
                name="font-size"
                checked={settings.fontSize == FontSizes.FONT_LARGE}
                onChange={() => setFontSize(FontSizes.FONT_LARGE)}
              />
              <label htmlFor="font-lg">{t("large")}</label>
            </div>
          </div>

          <hr
            style={{
              margin: "28px 8px 24px 8px",
              border: "1px solid #eee",
            }}
          />

          {/* Background music */}
          <div className="check-options">
            <h3 className="check-options-h3">{t("background_music")}</h3>
            <label className="switch">
              <input
                type="checkbox"
                checked={playing}
                onChange={(event) => setSoundPlaying(event.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <hr
            style={{
              margin: "28px 8px 24px 8px",
              border: "1px solid #eee",
            }}
          />

          {/* Reinitialize parameters */}
          {/* <div className="check-options">
            <h3 className="check-options-h3">Réinitialiser les paramétre</h3>
            <a className="settings-header-a" onClick={() => resetSettings()}>
              Réinitialiser
            </a>
          </div> */}
        </div>

        <div
          className="preview-container"
          style={{
            fontFamily: `tifinagh${
              settings.fontType !== undefined ? settings.fontType : defaultFont
            }`,
            fontSize: `${settings.fontSize}vw`,
          }}
        >
          <h2 className="preview-item">ⵜⵉⵏⴼⵓⵙⵉⵏ ⵉⵏⵓ</h2>
        </div>
      </div>

      {/* {isElectron() && (
        <div>
          <hr style={{ margin: "0px 8px 12px 8px", border: "1px solid #eee" }} />
          <div className="check-options" style={{ margin: "16px", fontSize: "18px" }}>
            <h3 className="check-options-h3">Les contes téléchargés</h3>
            <Scrollable>
              {stories.map((card, index) => (
                <StoryThumbnail
                  story={card}
                  currentStoryId={props.location.story.id}
                  history={history}
                />
              ))}
            </Scrollable>
          </div>
        </div>
      )} */}
    </div>
  );
}

const StoryThumbnail = (props) => {
  const { story, currentStoryId, history } = props;

  useEffect(() => {
    window["api"].receive("storyDeleted", (data) => {
      if (data === "Done" && story.id === currentStoryId) history.push({ pathname: "/" });
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        src={story.coverImage}
        style={{
          height: "10rem",
          width: "10rem",
          borderRadius: "5rem",
          border: "0.3rem solid black",
        }}
      />

      <label
        style={{
          position: "absolute",
          cursor: "pointer",
          bottom: "0",
        }}
        onClick={() => {
          if (window.confirm("Voulez-vous vraiment supprimer ce conte?")) {
            window["api"].send("deleteStory", story.id);
            // if (story.id === currentStoryId) {
            //   history.push({
            //     pathname: "/",
            //   });
            // }
          }
        }}
      >
        <img src={trashIcon} style={{ height: "3rem" }} />
      </label>
    </div>
  );
};

export default SettingsScreen;
