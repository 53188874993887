import axios from "axios";
import ApiService from "./api.service";
import { Story } from "../models/story";
import { CellType } from "../models/cell.type";
import { Archive } from "libarchive.js/main.js";
import { ENDPOINT_URL } from "../utils/Constants";
import isElectron from "is-electron";
require("es6-promise").polyfill();

Archive.init({
  workerUrl: "libarchive.js/dist/worker-bundle.js",
});
const URL_PREFIX = isElectron() ? ENDPOINT_URL : "";

export default class HomeService extends ApiService {
  private static instance: HomeService;
  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new HomeService();
    }
    return this.instance;
  }

  public getAllStories() {
    return this.apiGet<Story[]>(`${URL_PREFIX}/api/story`);
  }

  public getStoryById(storyId: number) {
    return this.apiGet<Story>(`${URL_PREFIX}/api/story/${storyId}`);
  }

  public getAllCellType() {
    return this.apiGet<CellType[]>(`${URL_PREFIX}/api/story/cell-type/all`);
  }

  public downloadZipFile(storyId: number, setPercentage) {
    return new Promise<Object>((resolve, reject) => {
      axios({
        url: `${URL_PREFIX}/api/public/stories/${storyId}.zip`,
        responseType: "arraybuffer",
        onDownloadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (percentCompleted < 100) setPercentage(percentCompleted);
        },
      })
        .then(async ({ data }) => {
          // console.log('data: ', data);
          const archive = await Archive.open(new Blob([data]));
          let obj = await archive.extractFiles();
          resolve(obj);
          setPercentage(100);
        })
        .catch((error) => {
          console.log(error);
          const errorData = !error.response
            ? { message: "Network disconnect" }
            : error.response.data;
          const statusCode =
            error.response && error.response.status ? error.response.status : undefined;

          reject({
            ...errorData,
            status_code: statusCode,
          });
        });
    });
  }

  public subscribeToTopic(token) {
    return this.apiPost(`${URL_PREFIX}/api/notification/subscribe-token-to-topic`, {token:token});
  }
}
