/* eslint-disable */
// import isElectron from "is-electron";
import React, { useState } from "react";
import triangle from "../../assets/images/triangle.webp";
import "./../../assets/css/progressBar.css";

/**
 * Le composant qui affiche l'avancement du lecteur
 * @component
 * @param props 
 * @param props.template {Number} - Le gabarit de la page dans laquelle le composant s'affichera
 * @param props.numPage {Number} - Le numéro de la page courante
 * @param props.nbPages {Number} - Le nombre des pages du conte
 */
const ProgressBar = (props) => {
  const { currentPageNumber, totalPages } = props;
  
  return (
    // <div
    //   style={{
    //     width: "100%",
    //     height: "100%",
    //     position: "absolute",
    //     display: "flex",
    //     justifyContent: template === 1 ? "flex-start" : "flex-end",
    //   }}
    // >
      <div className="progressBarContainer">
        
        <div className="progress" style={{ width: `${((currentPageNumber - 1) / totalPages) * 100}%`, height: "5px", marginRight: "-2px" }}></div>
        <div>
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={triangle} className="triangle" />
            <span className="progressBarLabel">{currentPageNumber}</span>
          </span>
        </div>
        <div style={{backgroundColor: "#b9b9ba", width: `${100 - ((currentPageNumber / totalPages) * 100)}%`, height: "5px" , marginLeft: "-2px"}}></div>
      </div>
    // </div>
  );
};

export default ProgressBar;
