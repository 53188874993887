/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../assets/css/continueDialog.css";
import "../../assets/css/unlockGameDialog.css";
import closeIcon from "../../assets/images/icons/ic_close.png";
import playIcon from "../../assets/images/icons/ic_sound.png";
import doneIcon from "../../assets/images/icons/ic_done.png";
import arrowIcon from "../../assets/images/arrow.png";
import Sound from "react-sound";
import isElectron from "is-electron";

/**
 * Le dictionnaire
 * @component
 * @param props
 * @param props.showDialog {boolean} - Indique si le dictionnaire doit être affiché ou non
 * @param props.setShowDialog {Function} - Une fonction qui prend un booléen en paramètre et qui permet d'afficher/masquer le dictionnaire
 * @param props.page {Page}- la dernière page du livre
 * @param props.obj {Object} - Les ressources du conte
 */
function ContinueDialog(props) {
  const { obj, page1, page2, onValidate, setShowDialog } = props;
  const [urlImage1, setUrlImage1] = useState("");
  const [urlImage2, setUrlImage2] = useState("");

  const [isPreviousPage, setIsPreviousPage] = useState(true);

  useEffect(() => {
    if (!isElectron() && obj !== undefined && Object.keys(obj).length !== 0) {
      // image url for lastPage
      if (obj[Object.keys(obj)[0]].pages[page1.id]["illustration.png"] !== undefined) {
        const imageBlob =
          obj[Object.keys(obj)[0]].pages[page1.id]["illustration.png"] &&
          URL.createObjectURL(obj[Object.keys(obj)[0]].pages[page1.id]["illustration.png"]);
        setUrlImage1(imageBlob);
      }
    }
  }, []);

  useEffect(() => {
    if (!isElectron() && obj !== undefined && Object.keys(obj).length !== 0) {
      // image url for lastPage
      if (obj[Object.keys(obj)[0]].pages[page2.id]["illustration.png"] !== undefined) {
        const imageBlob =
          obj[Object.keys(obj)[0]].pages[page2.id]["illustration.png"] &&
          URL.createObjectURL(obj[Object.keys(obj)[0]].pages[page2.id]["illustration.png"]);
        setUrlImage2(imageBlob);
      }
    }
  }, []);

  function onClose() {
    setShowDialog(false);
  }

  function validateChoice() {
    isPreviousPage ? onValidate() : onClose();
  }

  return (
    <div>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
          if ((e.target as HTMLDivElement).className === "modal-container") {
            onClose();
          }
        }}
      >
        <div className="modal-window-2">
          <div
            className="modal-body"
            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <div
              style={{
                position: "relative",
                cursor: "pointer",
                marginRight: "2vw"
              }}
              onClick={() => setIsPreviousPage(false)}
            >
              <img
                src={isElectron() ? page1.image : urlImage1}
                style={{
                  width: "14vw",
                  objectFit: "contain",
                  border: !isPreviousPage ? "medium solid white" : "none",
                  padding: "0.5vw",
                }}
                alt=""
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "1.2vw",
                  right: "1vw",
                }}
              >
                <span
                  style={{
                    width: "2.2vw",
                    height: "2.2vw",
                    color: "white",
                    fontSize: "1.2vw",
                    background: "rgba(0, 0, 0, 0.4)",
                    paddingTop: "0.3vw",
                    borderRadius: "50%",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  {page1.order}
                </span>
              </div>
            </div>

            {/**<img
              src={arrowIcon}
              style={{
                objectFit: "contain",
                width: "4vw",
                marginRight: "2vw",
                marginLeft: "2vw",
              }}
              alt=""
            />*/}

            <div
              style={{
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => setIsPreviousPage(true)}
            >
              <img
                src={isElectron() ? page2.image : urlImage2}
                style={{
                  width: "14vw",

                  objectFit: "contain",
                  border: isPreviousPage ? "medium solid white" : "none",
                  padding: "0.5vw",
                }}
                alt=""
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "1.2vw",
                  right: "1vw",
                }}
              >
                <span
                  style={{
                    width: "2.2vw",
                    height: "2.2vw",
                    color: "white",
                    fontSize: "1.2vw",
                    background: "rgba(0, 0, 0, 0.4)",
                    paddingTop: "0.3vw",
                    borderRadius: "50%",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  {page2.order}
                </span>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <img className="pop-button" src={closeIcon} onClick={() => onClose()} />
            <img
              className="pop-button pop-right"
              style={{ marginLeft: "auto" }}
              src={doneIcon}
              onClick={() => validateChoice()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ContinueDialog;
