/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import "../../assets/css/dictionaryDialog.css";
import closeIcon from "../../assets/images/icons/ic_close.png";
import playIcon from "../../assets/images/icons/ic_sound.png";
import Sound from "react-sound";
import isElectron from "is-electron";
import { SettingsContext } from '../../hooks/SettingsContext';

/**
 * Le dictionnaire
 * @component
 * @param props
 * @param props.showDialog {boolean} - Indique si le dictionnaire doit être affiché ou non
 * @param props.setShowDialog {Function} - Une fonction qui prend un booléen en paramètre et qui permet d'afficher/masquer le dictionnaire
 * @param props.page {Page}- La page courante
 * @param props.obj {Object} - Les ressources du conte
 * @param props.isToggled {boolean} - Determine si le dictionnaire est activé ou non
 * @param props.dictionary {Dictionary} - Le dictionnaire du mot tel que récupéré de l'API
 */
function DictionaryDialog(props) {
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const { dictionary } = props;

    const {lang, setLang} = useContext(SettingsContext);

  const [url_image_dictionary, set_url_image_dictionary] = useState("");
  const [url_audio_dictionary, set_url_audio_dictionary] = useState("");

  useEffect(() => {
    // let url_audio_dictionary, url_image_dictionary = "";
    const obj = props.obj;
    if (!isElectron() && obj !== undefined && Object.keys(obj).length !== 0) {
      // image and audio url for dictionary
      if (
        props.isToggled &&
        props.dictionary &&
        props.dictionary.id &&
        obj[Object.keys(obj)[0]].pages[props.page.id].dictionary !== undefined &&
        obj[Object.keys(obj)[0]].pages[props.page.id].dictionary[props.dictionary.item_id] !==
          undefined
      ) {
        const audioBlob = URL.createObjectURL(
          new Blob(
            [
              obj[Object.keys(obj)[0]].pages[props.page.id].dictionary[
                props.dictionary.item_id
              ]["audio.mp3"],
            ],
            { type: "audio/mpeg" }
          )
        );
        const imageBlob =
          obj[Object.keys(obj)[0]].pages[props.page.id].dictionary[props.dictionary.item_id][
            "image.png"
          ] &&
          URL.createObjectURL(
            obj[Object.keys(obj)[0]].pages[props.page.id].dictionary[props.dictionary.item_id][
              "image.png"
            ]
          );
        set_url_audio_dictionary(audioBlob);
        set_url_image_dictionary(imageBlob);
      }
    }
    onPlay();
  }, []);

  function onClose() {
    props.setShowDialog(false);
    if (playStatus === Sound.status.PLAYING) {
      setPlayStatus(Sound.status.STOPPED);
    }
  }

  function onPlay() {
    setPlayStatus((prev) =>
      prev === Sound.status.STOPPED || prev === Sound.status.PAUSED
        ? Sound.status.PLAYING
        : Sound.status.PAUSED
    );
  }

  function handleSongFinishedPlaying() {
    if (playStatus === Sound.status.PLAYING) {
      setPlayStatus(Sound.status.STOPPED);
    }
  }

  return (
    <div>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
          if ((e.target as HTMLDivElement).className === "modal-container") {
            onClose();
          }
        }}
      >
        <div className="modal-window">
          <div
            className="modal-body"
            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          >
            <img
              src={isElectron() ? dictionary.image : url_image_dictionary}
              style={{ objectFit: "contain", "maxHeight": "30vw" }}
              alt=""
            />
            <span style={{ fontSize: "40px", color: "white" }}>{dictionary.content}</span>
          </div>
          {/* <div className="modal-footer">
            <img className="modal-close-btn" src={closeIcon} onClick={() => onClose()} />
            <img className="modal-play-btn" src={playIcon} onClick={() => onPlay()} />
          </div> */}
          <div className="pop-actions">
          <img className="pop-button" src={closeIcon} onClick={() => onClose()}/>
          <img className={'pop-button '+ (lang === 'ar' ? 'pop-left' : 'pop-right')}
            src={playIcon} onClick={() => onPlay()} />
        </div>
        </div>
      </div>
      <Sound
        url={isElectron() ? dictionary.audio : url_audio_dictionary}
        playStatus={playStatus}
        autoLoad={true}
        onFinishedPlaying={handleSongFinishedPlaying}
      />
    </div>
  );
}
export default DictionaryDialog;
