// import { prependOnceListener } from 'node:process';
import "../../assets/css/scrollable.css"
import React, { useEffect, useRef, useState } from "react";
import isElectron from "is-electron";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

/**
 * 
 * @param props 
 * @returns 
 */
const Scrollable = (props) => {
  const { children, className, skipCount } = props;
  const [state, setState] = useState({
    isScrolling: false,
    clientX: 0,
    scrollX: 0,
  });

  const [isScrolling, setIsScrolling] = useState<boolean>(false)
  const [startX, setStartX] = useState<number>(0)
  const [scrollLeft, setScrollLeft] = useState<number>(0)
  const [totalItems, setTotalItems] = useState<number>(0)

  const scroller = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let tms = 0

    if (isElectron() && !skipCount) {
      if (children[0].length) {
        for (let index = 0; index < children[0].length; index++) {
          const element = children[0][index];
          if (element) {
            tms += 1
          }
        }
        // tms += children[0].filter(e => e === undefined).length
      }

      if (children[1].length) {
        for (let index = 0; index < children[1].length; index++) {
          const element = children[1][index];
          if (element) {
            tms += 1
          }
        }
        // tms += children[1].filter(e => e === undefined).length
      }
    }
    else {
      tms = children.length
    }
    setTotalItems(tms)

  }, [children])

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    if (scroller && scroller.current) {
      setIsScrolling(true)
      setStartX(e.pageX - scroller.current.offsetLeft)
      setScrollLeft(scroller.current.scrollLeft)
      // setState({ ...state, isScrolling: true, clientX: e.clientX });
    }
  };

  const handleMouseUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault()
    if (scroller && scroller.current) {
      setIsScrolling(false)
      // setState({ ...state, isScrolling: false });
    }

  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isScrolling && scroller && scroller.current) {
      e.preventDefault();
      const x = e.pageX - scroller.current.offsetLeft;
      const walk = (x - startX);
      scroller.current.scrollLeft = scrollLeft - walk;
    }
  };

  const handleArrowMove = (speed: number) => {
    if (scroller && scroller.current) {
      scroller.current.scroll({ left: scroller.current.scrollLeft + speed, behavior: "smooth" })
      // scroller.current.scrollLeft += ;
    }
  };


  return (
    <div className="" style={{ display: "flex", flexWrap: "nowrap", padding: 0, width: "100%" }}>
      {totalItems > 3 && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "auto" }}>
        <ArrowBackIosIcon
          viewBox="-4 0 24 24"
          onClick={() => handleArrowMove(-400)}
          style={{ width: "2rem", height: "2rem", margin: 6, cursor: "pointer", color: "white", background: "#2e689fd2", borderRadius: 4 }}
        />
      </div>}


      <div
        className={`${className ? className + " " : ""}items`}
        style={{
          display: "flex",
          overflowX: "scroll",
          overflowY: "hidden",
          flexDirection: "row",
          justifyContent: totalItems > 3 ? "start" : "center",
          height: "100%",
          // scrollbarWidth: "auto"
        }}
        ref={scroller}
        onMouseDown={e => handleMouseDown(e)}
        onMouseUp={e => handleMouseUp(e)}
        onMouseMove={e => handleMouseMove(e)}
      >

        {totalItems > 3 || skipCount ?
          <div style={{ display: "flex", justifyContent: "start", alignItems: "center", height: "100%" }}>{children}</div> :
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>{children}</div>}
      </div>

      {totalItems > 3 && <div style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "auto" }}>
        <ArrowForwardIosIcon
          viewBox="0 0 24 24"
          onClick={(e) => handleArrowMove(400)}
          style={{ width: "2rem", height: "2rem", margin: 6, cursor: "pointer", color: "white", background: "#2e689fd2", borderRadius: 4 }}
        />
      </div>}
    </div>
  );
};

export default Scrollable;
