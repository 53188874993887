export const DB_NAME = "stories";
// 54.200.126.221
// 81.192.4.235
export const ENDPOINT_URL = "http://tinfusin.ircam.ma"; 

/**
 * Constant pour les police
 */
export const FontSizes = {
  FONT_SMALL: 2,
  FONT_MEDIUM: 2.2,
  FONT_LARGE: 2.4,
};

/**
 * Constant pour les layouts
 */
export const Layouts = {
  SPLASH: "splash",
  HOME: "home",
  HOME_SETTINGS: "home_settings",
  BOOK: "book",
  GAME: "game",
  SETTINGS: "settings",
};