/* eslint-disable */
import isElectron from "is-electron";
import { useState, useEffect } from "react";
import { Page } from "../models/page";
/**
 * Un Hook personnalisé qui retourne un tableau de pages
 * @param storyId {Number} - L'identifiant du conte conerné
 * @param storyData {Object} - Un paramètre optionnel qu'on utilise si on a un conte sauvegardé dans la session
 * @param fetching {Function} - Une fonction qui prend en paramètre un booléen indiquant que la récupération des pages est en cours
 * 
 */
export default function usePages(storyId: number, storyData: any, fetching: Function) {
  const [pages, setPages] = useState<Page[]>([]);

  useEffect(() => {
    refreshPages(storyId);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function refreshPages(storyId) {
    if (isElectron()) {
      window["api"].send("getLocalStories", null);
      window["api"].receive("localStories", (stories) => {
        let story = stories && stories.find((story) => story.id === storyId);
        setPages(story && story.pages || []);
        fetching(false);
      });
    } else {
      setPages(storyData.pages || []);
      fetching(false);
      console.log("storyData.pages", storyData.pages || []);
    }
  }

  return {
    pages,
    refreshPages,
  };
}
