import React from "react";

/**
 * Affichage de l'image
 * @param props 
 * @returns 
 */
const Image = (props) => {
  const { className, style, urlImage } = props;

  return <img className={className} style={style} src={urlImage} alt="" />;
};

export default Image;
