/* eslint-disable */
import React, { useState, useEffect, memo } from "react";
const isElectron = require("is-electron");

/**
 * 
 * @param props 
 * @returns 
 */
const TextItem = (props) => {
  const { item, audioPos, handleItemClicked, isToggled, currentPageId, page, style } = props;

  const [highlightColor, setHighlightColor] = useState("rgba(0,0,0,0)");
  const [isPronounced, setIsPronounced] = useState<Boolean>(false);
  const [defaultHightlightColor, setDefaultHighlightColor] = useState("rgba(0,0,0,0)");
  const [textColor, setTextColor] = useState("");

  const propsStyle = {
    backgroundColor: highlightColor !== undefined ? highlightColor : "rgba(0,0,0,0)",
    cursor: "pointer",
    color: textColor,
    marginBottom: "3px",
    ...style,
  };

  useEffect(() => {
    if (currentPageId === page.id) {
      if (item.start_time < audioPos && item.end_time > audioPos) setIsPronounced(true);
      else setIsPronounced(false);

      if (isToggled && item.dictionary) {
        setDefaultHighlightColor("#f2d9b5");
        setTextColor("#000");
      } else {
        setDefaultHighlightColor("rgba(0,0,0,0)");
        setTextColor("");
      }
      if (isPronounced) {
        setHighlightColor("#bcd9f2");
      } else {
        setHighlightColor(defaultHightlightColor);
      }

      if (!isElectron()) {
        const isPronounced =
          item.start_time <= props.audioPos &&
          item.end_time > props.audioPos &&
          props.audioPos !== 0;

        if (isPronounced) {
          setHighlightColor("#60c4e8");
          setTextColor("#fff");
        } else if (isToggled && item.dictionary) {
          setHighlightColor("#f2d9b5");
          setTextColor("#000");
        } else {
          setHighlightColor("rgba(0,0,0,0)");
          setTextColor("");
        }
      }
    }
  }, [props, item, defaultHightlightColor, isToggled, audioPos, isPronounced]);

  return (
    <p className={props.className} style={propsStyle} onClick={() => handleItemClicked(item)}>
      {item.content}&nbsp;
    </p>
  );
};

// const hasChanged = (prevProps, nextProps) => {
//   const isPronounced =
//     prevProps.item.start_time <= prevProps.audioPos &&
//     prevProps.item.end_time > prevProps.audioPos &&
//     prevProps.audioPos !== 0;

//   const isPronouncedAfter =
//     nextProps.item.start_time <= nextProps.audioPos &&
//     nextProps.item.end_time > nextProps.audioPos &&
//     nextProps.audioPos !== 0;

//   if (prevProps.item.content === "ⴰⵙⵙⴰ," && nextProps.item.content === "ⴰⵙⵙⴰ,") {
//     console.log("prevProps", prevProps);
//     console.log("nextProps", nextProps);
//     console.log("isRendered", isPronounced === isPronouncedAfter);
//   }

//   return (
//     isPronounced === isPronouncedAfter &&
//     (prevProps.item.dictionary === undefined || prevProps.isToggled === nextProps.isToggled)
//   );
// };

export default memo(TextItem);
