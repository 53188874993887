/* eslint-disable */
import React, { useEffect, useState } from "react";
import "../../assets/css/dictionaryDialog.css";
import "../../assets/css/unlockGameDialog.css";
import closeIcon from "../../assets/images/icons/ic_close.png";
import playIcon from "../../assets/images/icons/ic_sound.png";
import arrowIcon from "../../assets/images/arrow.png";
import Sound from "react-sound";
import isElectron from "is-electron";
import "../../assets/css/arrow.css";

/**
 * Le dictionnaire
 * @component
 * @param props
 * @param props.showDialog {boolean} - Indique si le dictionnaire doit être affiché ou non
 * @param props.setShowDialog {Function} - Une fonction qui prend un booléen en paramètre et qui permet d'afficher/masquer le dictionnaire
 * @param props.page {Page}- la dernière page du livre
 * @param props.obj {Object} - Les ressources du conte
 */
function UnlockGameDialog(props) {

  const [url_image_last_page, set_url_image_last_page] = useState("");

  useEffect(() => {
    const obj = props.obj;
    let imageBlob
    if (isElectron()){
      imageBlob=props.page.image;
    }else {
      if(obj !== undefined && Object.keys(obj).length !== 0) {
        // image url for lastPage
        if (
          obj[Object.keys(obj)[0]].pages[props.page.id]["illustration.png"] !== undefined
        ) {
          
          imageBlob =
            obj[Object.keys(obj)[0]].pages[props.page.id]["illustration.png"] &&
            URL.createObjectURL(
              obj[Object.keys(obj)[0]].pages[props.page.id]["illustration.png"]
            );
          set_url_image_last_page(imageBlob);
        }
      }
    } 
  }, []);

  function onClose() {
    props.setShowDialog(false);
  }

  return (
    <div>
      <div
        className="modal-container"
        onClick={(e) => {
          e.stopPropagation();
          if ((e.target as HTMLDivElement).className === "modal-container") {
            onClose();
          }
        }}
      >
        <div className="modal-window">
          <div
            className="modal-body"
            style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
          >
            <img
              id="arrow-button"
              src={arrowIcon}
              style={{ objectFit: "contain", height: "60px",marginRight: "10px" }}
              alt=""
            />
            
            <div
              style={{
                position: "relative",
                cursor: "pointer",
              }}
            >
              <img
                src={isElectron() ? props.page.image : url_image_last_page}
                style={{ width:"14vw", objectFit: "contain" }}
                alt=""
              />
              {//<img
              //  src={isElectron() ? page1.image : urlImage1}
              //  style={{
              //    width: "14vw",
              //    objectFit: "contain",
              //    border: !isPreviousPage ? "medium solid white" : "none",
              //    padding: "0.5vw",
              //  }}
              //  alt=""
              //>
              }
              <div
                style={{
                  position: "absolute",
                  bottom: "1.2vw",
                  right: "1vw",
                }}
              >
                <span
                  style={{
                    width: "2.2vw",
                    height: "2.2vw",
                    color: "white",
                    fontSize: "1.2vw",
                    background: "rgba(0, 0, 0, 0.4)",
                    paddingTop: "0.3vw",
                    borderRadius: "50%",
                    display: "inline-block",
                    textAlign: "center",
                  }}
                >
                  {props.page.order}
                </span>
              </div>
              </div>
            </div>
           <div className="modal-footer">
          <img 
            className="pop-button"
            src={closeIcon} onClick={() => onClose()}
          />
        </div>
        </div>
      </div>
    </div>
  );
}
export default UnlockGameDialog;
