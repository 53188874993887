/* eslint-disable */
import "../../assets/css/popupDialog.css";
import React, {useContext} from "react";
import closeIcon from "../../assets/images/icons/ic_close.png";
import doneIcon from "../../assets/images/icons/ic_done.png";
import { SettingsContext } from '../../hooks/SettingsContext';

/**
 * Boîte de dialogue contextuelle
 * 
 * @param props 
 * @returns 
 */
function PopupDialog(props) {
  const { body, onValidate, onCancel, showDialog, confirmIcon, cancelIcon, noOverlay, sticky } = props;
  const {lang, setLang} = useContext(SettingsContext);

  const onClickAway = (e) => {
    if(!sticky) {
      e.stopPropagation();
      if ((e.target as HTMLDivElement).className === "pop-bg") 
        onCancel && onCancel();
    }
  };

  if (!showDialog) return null;
  return (
    <div className="pop-bg" style={{backgroundColor: `rgba(0, 0, 0, ${noOverlay? "0": "0.75"})`}} onClick={onClickAway}>
      <div className="pop">
        <div className="pop-body">{body}</div>

        <div className="pop-actions">
          {onCancel && <img
            className="pop-button"
            src={cancelIcon ? cancelIcon : closeIcon}
            onClick={() => onCancel && onCancel()}
          />}
          {onValidate && <img
            className={'pop-button '+ (lang === 'ar' ? 'pop-left' : 'pop-right')}
            src={confirmIcon ? confirmIcon : doneIcon}
            onClick={() => onValidate && onValidate()}
          />}
        </div>
      </div>
    </div>
  );
}
export default PopupDialog;
