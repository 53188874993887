/* eslint-disable */
import { useState, useEffect } from "react";
import { Item } from "../models/item";
/**
 * Hook personnalisé permettant de récupérer les mots d'une page
 * @method
 * @param page {Page} - La page concernée
 * @param cellType {Number} - Le type de la cellule dont on désire récupérer les mots
 */
export default function useItems(page, cellType) {
  const [items, setItems] = useState<Item[]>([]);

  useEffect(() => {
    getItems();
  }, []);

  function getItems() {
    const cellItems: Item[] = [];
    let usedCell: any;

    if (page.cells && page.cells.length > 0) {
      usedCell = page.cells.find((cell) => cell.cell_type_id === cellType);
      console.log("usedCell", usedCell);
    }

    if (usedCell) {
      usedCell.items.forEach((item) => cellItems.push(item));
      console.log("cellItems", cellItems);
    }

    setItems(cellItems);
  }

  return { items, getItems };
}
