/* eslint-disable */
import React, { useEffect, useState } from "react";
import Sound from "react-sound";
import { isSafari } from "react-device-detect";

/**
 * Composant pour la carte
 * @param props 
 * @returns 
 */
const FlipCard = (props: any) => {
  const { cardData, level, coverImage } = props;
  const [flipped, setFlipped] = useState(false);
  const [randomNumber] = useState(Math.floor(Math.random() * 3));
  const [found, setFound] = useState(false);
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);

  // Display Id on the card for easy debugging
  const [isDebug] = useState(false);

  const shouldGiveUserTimeToMemorizeCards = false;

  const styles = {
    Card: {
      height: level < 3 ? 150 : 105,
      width: level < 3 ? 150 : 105,
      margin: level < 3 ? 20 : 10,
      transform: flipped ? "rotateY(180deg)" : "rotateY(0deg)",
      transition: "transform 0.8s ease-in-out",
      transformStyle: "preserve-3d" as "preserve-3d",
      cursor: "pointer",
      position: "relative" as "relative",
      webkitBackfaceVisibility: isSafari ? "hidden" : "none" /* I added this */,
    },
  };

  useEffect(() => {
    console.log(cardData);
    if (shouldGiveUserTimeToMemorizeCards) {
      setFlipped(true);
      setTimeout(
        () => {
          setFlipped(false);
        },
        level === 1 ? 1000 : level === 2 ? 1300 : 1800
      );
    }
  }, []);

  useEffect(() => {
    if (props.foundCards) {
      setFound(props.foundCards.includes(cardData.id) && flipped);
    }
  }, [props.foundCards]);

  useEffect(() => {
    if (
      !found &&
      props.flippedCards.length > 1 &&
      flipped &&
      props.flippedCards.length % 2 === 0 &&
      (cardData.id === props.flippedCards[props.flippedCards.length - 1] ||
        cardData.id === props.flippedCards[props.flippedCards.length - 2])
    ) {
      if (
        props.flippedCards[props.flippedCards.length - 1] !==
        props.flippedCards[props.flippedCards.length - 2]
      ) {
        setTimeout(unflip, 800);
      }
    }
  }, [props.flippedCards]);

  useEffect(() => {
    if (found) props.handleScore();
  }, [found]);

  const handleClick = () => {
    if (flipped) unflip();
    else flip();
  };

  const flip = () => {
    setFlipped(true);
    props.handleFlip(cardData.id);
    setPlayStatus(Sound.status.PLAYING);
  };

  const unflip = () => {
    setFlipped(!found ? false : true);
    if (!found) props.handleUnflip(cardData.id);
  };

  const handleSoundFinishedPlaying = () => {
    setPlayStatus(Sound.status.STOPPED);
  };

  return (
    <div style={styles.Card} onClick={handleClick}>
      <Sound
        url={cardData.audio}
        playStatus={playStatus}
        onFinishedPlaying={handleSoundFinishedPlaying}
      />
      {/* front */}
      <div
        style={{
          position: "absolute",
          backfaceVisibility: "hidden",
          backgroundColor: "#2e689f",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "50px",
          color: "white",
        }}
      >
        <div
          style={{
            position: "absolute",
            backfaceVisibility: "hidden",
            backgroundColor: "rgba(46, 104, 159, 0.5)",
            width: "100%",
            height: "100%",
            fontSize: "50px",
            color: "rgba(46, 104, 159, 0.5)",
          }}
        ></div>
        <img
          src={coverImage}
          style={{ width: "100%", backgroundColor: "rgba(0,0,127, 0.5)" }}
        />
        <p style={{ position: "absolute" }}>? {isDebug && cardData.id} </p>
      </div>

      {/* back */}
      <div
        style={{
          backfaceVisibility: "hidden",
          transform: "rotateY(180deg)",
          position: "absolute",
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: level === 1 ? "26px" : level === 2 ? "24px" : "16px",
          color: "black" /* cardData.border_color, */,
          border: "7px solid " + cardData.border_color,
          //overflow: "auto",
        }}
      >
        {cardData.show === "text" ? (
          cardData.content
        ) : (
          <img
            src={cardData.image}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
            draggable={false}
            alt={cardData.content}
          />
        )}
      </div>
    </div>
  );
};

export default FlipCard;
