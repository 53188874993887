/* eslint-disable */
import "../../assets/css/pageTemplate1.css";
import "../../assets/css/pageTemplate2.css";
import isElectron from "is-electron";
import React, { memo, useEffect, useState } from "react";
import Image from "../components/page/Image";
import Text from "../components/page/Text";
import ProgressBar from "./ProgressBar";

/**
 * Composant chargé de faire les pages
 * 
 * @param props 
 * @returns 
 */
function PagesFactory(props) {
  const {
    storyAssets,
    handleItemClicked,
    isToggled,
    isCurrentPage,
    audioPos,
    currentPageId,
    page,
    setAudioURL,
    setAudioPos,
    currentPageNumber,
    totalPages,
  } = props;

  useEffect(() => {
    if (currentPageId === page.id) {
      setAudioPos(0);
      if (isElectron() && page.audio) {
        setAudioURL(page.audio);
      } else {
        console.log("ffff storyAssets" ,storyAssets);
        
        if (storyAssets && Object.keys(storyAssets) && Object.keys(storyAssets)[0] && storyAssets[Object.keys(storyAssets)[0]].pages[page.id]["audio.mp3"]) {
          let audioUrl = storyAssets[Object.keys(storyAssets)[0]].pages[page.id]["audio.mp3"];
          const blob = new Blob([audioUrl], { type: "audio/mpeg" });
          if (blob) setAudioURL(URL.createObjectURL(blob));
        }
        
      }
    }
  }, [currentPageId]);

  const [urlImage, setUrlImage] = useState("");
  useEffect(() => {
    let urlImage = "";
    if (isElectron()) urlImage = page.image;
    else if (storyAssets !== undefined && Object.keys(storyAssets).length !== 0) {
      urlImage =
        storyAssets[Object.keys(storyAssets)[0]].pages[page.id]["illustration.png"] &&
        URL.createObjectURL(
          storyAssets[Object.keys(storyAssets)[0]].pages[page.id]["illustration.png"]
        );
    }

    setUrlImage(urlImage);
  }, []);

  const ImageHolder = (
    <div className="imgHolder2">
      <Image key={page.id} id={page.id} className="imgTemplate2" urlImage={urlImage} />
    </div>
  );

  const TextHolder = (
    <div className="textHolder2">
      <ProgressBar
        template={1}
        currentPageNumber={currentPageNumber}
        totalPages={totalPages}
      />
      <Text
        page={page}
        audioPos={audioPos}
        handleItemClicked={handleItemClicked}
        isToggled={isToggled}
        currentPageId={currentPageId}
      />
    </div>
  );

  const getPage = (template) => {
    if (template === 1)
      return (
        <div className="containerTemplate2">
          {TextHolder}
          {ImageHolder}
        </div>
      );

    if (template === 2)
      return (
        <div className="containerTemplate2">
          {ImageHolder}
          {TextHolder}
        </div>
      );

    return undefined;
  };

  return <div style={{ display: "flex", height: "inherit" }}> {getPage(page.template)} </div>;
}

export default React.memo(PagesFactory);
