// import {store} from '../store';
import axios, { Method } from "axios";
require("es6-promise").polyfill();
interface RequestHeader {
  Accept: string;
  "Content-Type": string;
  Authorization?: string;
  "Accept-Language"?: string;
  // API_KEY: string;
}
/**
 * Ce module s'occupe des requêtes HTTP effectuées par les composants React
 * @class ApiService
 */
export default class ApiService {
  /**
   * La fonction qui fait des requêtes HTTP avec la methode GET
   * @method
   * @param url {string} - Le lien de l'API
   * @param params {*} - Les paramètres de la requête
   * @param hasToken {boolean} - Un booléen qui définit si l'utilisateur à un jeton d'accès ou non
   * @returns Un Promise avec la réponse reçue à partir du serveur
   */
  protected apiGet<T>(
    url: string,
    params: object = {},
    hasToken: boolean = false
  ): Promise<T> {
    return this.apiRun<T>("get", url, null, params, hasToken);
  }
  /**
   * La fonction qui fait des requêtes HTTP avec la methode POST
   * @param url {string} - Le lien de l'API
   * @param body {Object} - Le corps de la requête
   * @param params {*} - Les paramètres de la requête
   * @param hasToken {boolean} - Un booléen qui définit si l'utilisateur à un jeton d'accès ou non
   * @returns Un Promise avec la réponse reçue à partir du serveur
   */
  protected apiPost<T>(
    url: string,
    body: any = null,
    params: object = {},
    hasToken: boolean = false
  ): Promise<T> {
    return this.apiRun<T>("post", url, body, params, hasToken);
  }
  /**
   * La fonction qui fait des requêtes HTTP avec la methode PUT
   * @param url {string} - Le lien de l'API
   * @param body {Object} - Le corps de la requête
   * @param params {*} - Les paramètres de la requête
   * @param hasToken {boolean} - Un booléen qui définit si l'utilisateur à un jeton d'accès ou non
   * @returns Un Promise avec la réponse reçue à partir du serveur
   */
  protected apiPut<T>(
    url: string,
    body: any = null,
    params: object = {},
    hasToken: boolean = false
  ): Promise<T> {
    return this.apiRun<T>("put", url, body, params, hasToken);
  }

  /**
   * La fonction qui fait des requêtes HTTP avec la methode DELETE
   * @param url {string} - Le lien de l'API
   * @param params {*} - Les paramètres de la requête
   * @param hasToken {boolean} - Un booléen qui définit si l'utilisateur à un jeton d'accès ou non
   * @returns Un Promise avec la réponse reçue à partir du serveur
   */
  protected apiDelete<T>(
    url: string,
    params: object = {},
    hasToken: boolean = false
  ): Promise<T> {
    return this.apiRun<T>("delete", url, null, params, hasToken);
  }
  /**
   * Une fonction qui exécute les requêtes HTTP pour laquelle on spécifie la méthode
   * @param method {string} - La méthode de la requête à exécuter
   * @param url {string} - Le lien de l'API
   * @param body {Object} - Le corps de la requête
   * @param params {*} - Les paramètres de la requête
   * @param hasToken {boolean} - Un booléen qui définit si l'utilisateur à un jeton d'accès ou non
   * @returns Un Promise avec la réponse reçue à partir du serveur
   */
  private apiRun<T>(
    method: Method,
    url: string,
    body: any = null,
    params: object = {},
    hasToken: boolean = false
  ): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      axios({
        url,
        method,
        baseURL: "",
        params,
        data: body,
        headers: this.appendHeaders(hasToken),
        timeout: 30000,
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);

          const errorData = !error.response
            ? { message: "Network disconnect" }
            : error.response.data;

          const statusCode =
            error.response && error.response.status ? error.response.status : undefined;

          reject({
            ...errorData,
            status_code: statusCode,
          });
        });
    });
  }

  private appendHeaders(hasToken: boolean = false): RequestHeader {
    const headers: RequestHeader = {
      Accept: "application/json",
      "Content-Type": "application/json",
      // API_KEY: "",
    };

    if (hasToken) {
      // const sessionReducer = store.getState().session;
      // headers.Authorization = `Bearer ${sessionReducer.session.access_token}`;
      headers.Authorization = "Bearer GFHD65657ETRH-HTER67FHT";
    }

    return headers;
  }
}
