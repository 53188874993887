/* eslint-disable */
import "../assets/css/homeScreen.css";
import homeBackground from "./../assets/images/homeScreenBackground.webp";
import settingsIcon from "../assets/images/icons/ic_settings_home.png";
import React, { useContext, useEffect, useState } from "react";
import HomeService from "../services/home.service";
import isElectron from "is-electron";
import Card from "../ui/components/Card";
import Scrollable from "../ui/components/Scrollable";
import { useHistory } from "react-router-dom";
import { Story } from "../models/story";
import { Layouts } from "../utils/Constants";
import axios from "axios";
import { ENDPOINT_URL } from "../utils/Constants";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
/**
 * L'écran principal qui contient la liste des contes
 * @component
 * @param props
 * @param props.setScreen {Function} - Une fonction permettant de changer l'écran
 * @param props.sessionStories {Story[]} - La liste des contes sauvegardés dans la session
 * @param props.setSessionStory {Function} - Une fonction permettant de modifier les contes sauvegardés dans la session
 */
function HomeScreen(props) {
  const history = useHistory();

  const { setScreen, setSessionStory, sessionStories } = props;

  const [localStories, setLocalStories] = useState<Story[]>([]);
  const [webStories, setWebStories] = useState<Story[]>([]);

  const [loading, setLoading] = useState<boolean>(true);
  // const { stopSound } = useContext(SettingsContext);
  // useEffect(() => stopSound(), []);

  const cardsStyle = {
    backgroundImage: `url("${homeBackground}")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    display: "flex",
  };

  useEffect(() => {
    getLocalStories(window)
      .then((stories) => {
        setLocalStories(stories);
      })
      .catch((error) => { })
      .finally(() => {
        setLoading(false)
        getStoriesFromServer();
      });
  }, []);

  const getLocalStories = (window) => {
    return new Promise<any>((resolve, reject) => {
      if (isElectron()) {
        // if (this.localStories) resolve(this.localStories);
        // else {
        window["api"].send("getLocalStories", null);
        window["api"].receive("localStories", (result) => {
          if (result === "no_database") reject("no_database");
          else {
            window["api"].removeListener("getLocalStories");
            resolve(result);
          }
        });
        // }
      } else reject("not_in_desktop");
    });
  };

  const getStoriesFromServer = () => {
    if (isElectron()) {
      axios
        .get(ENDPOINT_URL + "/api/story")
        .then((res) =>
          setWebStories(() =>
            res.data.filter((i) => !storyExistsLocaly(i) && i.status === "published")
          )
        )
        .catch((error) => console.log(error));
    } else {
      HomeService.getInstance()
        .getAllStories()
        .then((result) => {
          setWebStories(result);
        });
    }
  };

  const handleClick = (id, story) => {
    if (isElectron()) {
      if (storyExistsLocaly(story)) {
        // playSound();
        history.push({
          pathname: "/story",
          storyId: id,
          story: story,
        });
      } else {
        window["api"].send("unzipStory", id);
        window["api"].receive("localStories", (stories) => {
          setLocalStories(stories);
          window["api"].removeListener("unzipStory");
          // getLocalStories();
          // getStoriesFromServer();
        });
      }
    } else {
      setScreen(Layouts.BOOK, id);
    }
  };

  const storyExistsLocaly = (story) => {
    let existsLocally = false;
    if (localStories && localStories.length > 0)
      localStories.forEach((s) => {
        if (s.id === story.id) existsLocally = true;
      });
    return existsLocally;
  };

  const storyAlreadyInSession = (story) => {
    let storyExists;
    if (sessionStories.length > 0)
      sessionStories.forEach((sessionStory) => {
        if (sessionStory.data.id === story.id) {
          storyExists = sessionStory;
          return;
        }
      });
    return storyExists;
  };

  return (
    <div style={{ height: "100%", width: "100%", position: "absolute" }}>
      <div className="cards-container" style={cardsStyle}>
        <img
          className="floatingButton"
          style={{
            position: "absolute",
            right: 0,
            margin: "16px",
            width: "62px",
            height: "40px",
            zIndex: 1000
          }}
          src={settingsIcon}
          alt=""
          onClick={() => {
            isElectron()
              ? history.push({ pathname: "/home-settings" })
              : setScreen(Layouts.HOME_SETTINGS);
          }}
        />
        {isElectron() ?
          (
            <Scrollable>
              {localStories && localStories.length > 0 &&
                localStories.map((story, index) => (
                  <Card
                    className={"item" + index}
                    key={"loc" + index}
                    story={story}
                    handleOpen={handleClick}
                    existsLocally={true}
                  />
                ))}
              {webStories.length > 0 &&
                webStories.map((story, index) => {
                  if (!storyExistsLocaly(story)) {
                    return (
                      <Card
                        className={"item" + index}
                        key={"web" + index}
                        story={story}
                        handleDownload={handleClick}
                        exsistsLocally={false}
                      />
                    );
                  }
                })}
            </Scrollable>
          ) :
          (
            <Scrollable>
              {webStories.length > 0 &&
                webStories.map((story, index) => {
                  const storyInSession = storyAlreadyInSession(story);
                  if (storyInSession)
                    return (
                      <Card
                        key={index}
                        story={storyInSession.data}
                        assets={storyInSession.assets}
                        handleOpen={handleClick}
                        handleSave={setSessionStory}
                      />
                    );
                  else
                    return (
                      <Card
                        key={index}
                        story={story}
                        handleOpen={handleClick}
                        handleSave={setSessionStory}
                      />
                    );
                })}
            </Scrollable>
          )}
      </div>
    </div>
  );
}

function LeftArrow() {
  const { scrollPrev } = useContext(VisibilityContext);

  return (
    <ArrowBackIosIcon
      onClick={() => scrollPrev()}
      style={{ height: "auto", margin: "6px", cursor: "pointer" }}
    />
  );
}

function RightArrow() {
  const { scrollNext } = useContext(VisibilityContext);

  return (
    <ArrowForwardIosIcon
      onClick={() => scrollNext()}
      style={{ height: "auto", margin: "6px", cursor: "pointer" }}
    />
  );
}

export default HomeScreen;
