import React, { useContext } from "react";
import { SettingsContext } from "../../../hooks/SettingsContext";
import useItems from "../../../hooks/useItems";
import TextItem from "../TextItem";

/**
 * Affichage du text
 * @param props 
 * @returns 
 */
const Text = (props) => {
  const { page, audioPos, handleItemClicked, isToggled, currentPageId } = props;

  const { settings } = useContext(SettingsContext);

  const { items: titleItems } = useItems(page, 1);
  const { items: subtitleItems } = useItems(page, 2);
  const { items: contentItems } = useItems(page, 3);

  return (
    <div
      className="storypage" 
      style={{ 
        height: "24vw",
        overflowY: "scroll"
        }}>
      {[titleItems, subtitleItems, contentItems].map(
        (selectedIems, cellIndex) =>
          selectedIems && (
            <div className={`cellType${cellIndex + 1}`}>
              {selectedIems.map(
                (item, index) =>
                  item && (
                    <TextItem
                      key={index}
                      className="textTemplate2"
                      item={item}
                      audioPos={audioPos}
                      handleItemClicked={handleItemClicked}
                      isToggled={isToggled}
                      currentPageId={currentPageId}
                      page={page}
                      style={{ fontSize: `${settings.fontSize + (1 - cellIndex * 0.5)}vw` }}
                    />
                  )
              )}
            </div>
          )
      )}
    </div>
  );
};

export default Text;
