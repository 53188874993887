import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.css';
import App from './App';
import './assets/lang/i18n';

ReactDOM.render(
    <App />,
  document.getElementById('root')
);

