import firebase from 'firebase/app';
import 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyARQ8TRxy_2YJznhDPuFnKJHNgOmQPf1yI",
  authDomain: "tinfusin-inu-notifications.firebaseapp.com",
  projectId: "tinfusin-inu-notifications",
  storageBucket: "tinfusin-inu-notifications.appspot.com",
  messagingSenderId: "999392459063",
  appId: "1:999392459063:web:520c02e2861ede58e7c2b2"
};
firebase.initializeApp(firebaseConfig);

export default firebase;