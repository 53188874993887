import isElectron from "is-electron";

const LAST_PAGE = "LAST_PAGE";
const IS_READ = "IS_READ";


/**
 * Une fonction permettant de définir la dernière page consultée par l'utilisateur
 * @method
 * @param {Number} storyId - l'ID du conte
 * @param {Numvber} pageIndex - L'indice de la page
 */
export function setLastPage(storyId, pageIndex) {
	if (isElectron()) {
		let entryKey = `${LAST_PAGE}_${storyId}`;
		let entryValue = pageIndex;
		window["api"].send('setConfigValue', { [entryKey]: entryValue });
	}
	else localStorage.setItem(`${LAST_PAGE}_${storyId}`, pageIndex);
}

/**
 * Une fonction permettant récupérer la dernière page consultée par l'utilisateur
 * @method
 * @param {Number} storyId - l'ID du conte
 * @param {Function} callback - Une fonction qui prend l'indice de la page en paramètre et effectue un traitement dessus
 */
export function getLastPage(storyId, callback) {
	if (isElectron()) {
		window["api"].send('getConfigValue', `${LAST_PAGE}_${storyId}`);
		window["api"].receive('lastPage', (value) => {
			if (value === true) {
				callback(0)
			}
			else {
				callback(value ? Number(value) : 0);
			}

			window['api'].removeListener('getConfigValue');
		});
	} else {
		const data = localStorage.getItem(`${LAST_PAGE}_${storyId}`);
		callback(data ? Number(data) : 0);
	}
}

/**
 * Une fonction permettant de modifier la configuration
 * 
 * @param {*} storyId 
 * @param {*} isRead 
 */
export function setIsRead(storyId, isRead) {
	if (isElectron()) {
		let entryKey = `${IS_READ}_${storyId}`;
		let entryValue = isRead;
		window["api"].send('setConfigValue', { [entryKey]: entryValue });
	}
	else localStorage.setItem(`${IS_READ}_${storyId}`, isRead);
}

/**
 * Une fonction permettant récupérer la configuration
 * 
 * @param {*} storyId 
 * @param {*} callback 
 */
export function getIsRead(storyId, callback) {
	if (isElectron()) {
		window["api"].send('getConfigValue', `${IS_READ}_${storyId}`);
		window["api"].receive('lastPage', (value) => {
			callback(value ? Boolean(value) : false);
			window['api'].removeListener('getConfigValue');
		});
	} else {
		const data = localStorage.getItem(`${IS_READ}_${storyId}`);
		callback(data ? Boolean(data) : false);
	}
}