/* eslint-disable */
import isElectron from "is-electron";
import React, { useEffect, useState } from "react";
import FlipCard from "./FlipCard";

interface ICard {
  id: number;
  image: string;
  audio: string;
  text: string;
  border_color: string;
  show: string;
}
/**
 * La grille du jeu
 * @component
 * @param props
 * @param props.level {Number} - Le niveau de la grille à construire
 * @param props.onGameOver {Function} - La fonction qui s'exécutera une fois que le jeu est terminé
 * @param props.onHandleScore {Function} - La fonction qui manipule le score
 * @param props.storyId {Number} - L'ID du conte
 * @param props.story {Story} - L'objet conte contenant toutes les ressources
 * @param props.cards - Les cartes telles que retournées par l'API
 * @param props.coverImage {string} - L'image de couverture du conte
 */
const Grid = (props: any) => {
  const { level, onGameOver, onHandleScore, storyId, story, cards, coverImage } = props;

  const [foundCards, setFoundCards] = useState<number[]>([]);
  const [flippedCards, setFlippedCards] = useState<number[]>([]);

  const [grid, setGrid] = useState<any[]>([]);
  const [gridUI, setGridUI] = useState<Object[]>([]);

  // Initial cards data
  const [cardsData, setCardsData] = useState<ICard[]>([]);
  useEffect(() => {
    if (isElectron()) setCardsData(cards);
    else {
      let cards: any[] = story.data.game_cards;
      for (let i = 0; i < cards.length; i++) {
        if (
          story.assets[storyId].game[cards[i].id]["card_image.png"] &&
          story.assets[storyId].game[cards[i].id]["card_audio.mp3"]
        ) {
          cards[i].image = URL.createObjectURL(
            story.assets[storyId].game[cards[i].id]["card_image.png"]
          );
          const blob = new Blob([story.assets[storyId].game[cards[i].id]["card_audio.mp3"]], {type: "audio/mpeg"});
          cards[i].audio = URL.createObjectURL(blob);
        }
      }
      setCardsData(cards);
    }
  }, []);

  useEffect(() => {
    setFoundCards([]);
  }, [level]);

  useEffect(() => {
    if (cardsData.length > 0) setGrid(generateGrid(level));
  }, [cardsData]);

  // useEffect(() => {
  //   setGridUI(buildGrid(level));
  // }, [grid]);

  useEffect(() => {
    if (
      (level === 1 && flippedCards.length === 4) ||
      (level === 2 && flippedCards.length === 8) ||
      (level === 3 && flippedCards.length === 16)
    ) {
      // showNextButton(true);
      setTimeout(() => { onGameOver();}, 500);
    }

    if (
      numberOfOccurrences(flippedCards, flippedCards[flippedCards.length - 1]) > 0 &&
      numberOfOccurrences(flippedCards, flippedCards[flippedCards.length - 1]) % 2 === 0
    ) {
      let myArray = [...foundCards, flippedCards[flippedCards.length - 1]];
      myArray = myArray.filter((item, index) => myArray.indexOf(item) === index);
      setFoundCards(myArray);
    }
    setGridUI(buildGrid(level, foundCards));
  }, [flippedCards]);

  useEffect(() => {
    if (foundCards.length > 0) {
      setGridUI(buildGrid(level, foundCards));
    } else {
      setGridUI([]);
      setGrid(generateGrid(level));
    }
  }, [foundCards]);

  const numberOfOccurrences = (myArray: number[], value: number) => {
    let counter = 0;
    for (let i in myArray) {
      if (myArray[i] === value) counter++;
    }
    return counter;
  };

  const handleFlip = (cardId: number) => {
    setFlippedCards((oldArray) => [...oldArray, cardId]);
  };

  const handleUnflip = (cardId: number) => {
    setFlippedCards((fc) => {
      let index = fc.indexOf(cardId);
      let newArray = [...fc];
      if (index != -1) {
        newArray.splice(index, 1);
      }
      return newArray;
    });
  };

  const shuffle = (array, count?) => {
    let counter = array.length;
    while (counter > 0) {
      let index = Math.floor(Math.random() * counter);
      counter--;
      let temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }

    if (count) return array.slice(0, count);
    else return array;
  }

  const duplicate = (originalArray: ICard[]) => {
    let array1 = JSON.parse(JSON.stringify(originalArray));
    let array2 = JSON.parse(JSON.stringify(originalArray));
    
    // Cards on array1 will show images
    // and cards on array2 will show text
    for (let i =0; i < array1.length; i++) {
      array1[i].show = "image";
      array2[i].show = "text";
    }

    return shuffle([...array1, ...array2]);
  };

  const generateGrid = (level: number): ICard[] => {
    setFlippedCards([]);
    let cardsArray: ICard[] = [];

    const cardCount = level === 1 ? 2 : level === 2 ? 4 : 8;
    cardsArray = shuffle(cardsData, cardCount);

    const cardColors = [
      '#6022CF',
      '#DC185B',
      '#362222',
      '#FF652E',
      '#C026D3',
      '#fd558f',
      '#5D1451',
      '#4D7C0F',
    ];

    cardsArray.map((card, index) => {
      card["border_color"] = cardColors[index];
      return card;
    });

    cardsArray = duplicate(cardsArray);

    return cardsArray;
  };

  const handleScore = () => {
    onHandleScore();
  };

  const buildGrid = (level: number, foundCards?: number[]) => {
    if (grid.length > 0) {
      
      let rows = level <= 2 ? 2 : 4;
      let columns = level <= 1 ? 2 : 4;
      let game_grid: any[] = [];
      let counter = 0;

      for (let i = 0; i < rows; i++) {
        let row: any = [];

        for (let j = 0; j < columns; j++) {
          row.push(
            <FlipCard
              key={counter}
              coverImage={coverImage}
              cardData={grid ? grid[counter] : null}
              handleFlip={handleFlip}
              handleUnflip={handleUnflip}
              foundCards={foundCards ? foundCards : null}
              flippedCards={flippedCards ? flippedCards : null}
              handleScore={handleScore}
              level={level}
            />
          );
          counter++;
        }

        game_grid.push(<div style={{ display: "flex" }}>{row}</div>);
      }
      return game_grid;
    } else return [<></>];
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", }}>
      {gridUI}
    </div>
  );
};

export default Grid;
