/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Grid from "../ui/components/Grid";
import "../assets/css/gameScreen.css";
import "../assets/css/card.css";
import backgroundImage from "../assets/images/homeScreenBackground.webp";
import headerImage from "../assets/images/bar.webp";
import isElectron from "is-electron";
import { Layouts } from "../utils/Constants";
import { ENDPOINT_URL } from "../utils/Constants";
import timeIcon from "../assets/images/icons/clock_1.webp";
import level1Icon from "../assets/images/icons/level_1.webp";
import level2Icon from "../assets/images/icons/level_2.webp";
import level3Icon from "../assets/images/icons/level_3.webp";
import decorator from "../assets/images/decorator.webp";
import scoreIcon from "../assets/images/icons/score.webp";
import continueIcon from "../assets/images/icons/ic_continue.png";
import playIcon from "../assets/images/icons/ic_play.png";
import GameOverScreen from "./GameOverSceen";
import homeImage from "../assets/images/bar/buttons/home.webp";

const GameScreen = (props) => {
  const { setScreen, activeStory, location } = props;
  const history = useHistory();

  const [storyId] = useState(location ? location.storyId : activeStory.data.id);
  const [level, setLevel] = useState(1);
  const [score, setScore] = useState(0);
  const [time, setTime] = useState({ minutes: 0, seconds: 0 });
  const [endTime, setEndTime] = useState({ minutes: 0, seconds: 0 });
  const [gameOver, setGameOver] = useState(false);
  const [nextButton, showNextButton] = useState(false);

  const [isStarting, setIsStarting] = useState(false);

  const onGameOver = () => {
    showNextButton(true);
  };

  const onNextGame = () => {
    showNextButton(false);
    if (level < 3) setLevel((l) => l + 1);
    else setGameOver(true);
  };

  useEffect(() => {
    if (gameOver) setEndTime(time);
  }, [gameOver]);

  useEffect(() => {
    if (isStarting) {
      const startTime = new Date().getTime();

      let timer = setInterval(() => {
        let currentTime = new Date().getTime() - startTime;
        let seconds = 1 / 1000;
        let minutes = Math.floor((currentTime * 1) / 1000 / 60);
        setTime({
          minutes: minutes,
          seconds: Math.floor(currentTime * seconds) - minutes * 60,
        });
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [isStarting]);

  const handleScore = () => {
    setScore((s) => (time.seconds <= 15 && time.minutes === 0 ? s + 75 : s + 50));
  };

  if (!isElectron()) {
    window.onpopstate = (e) => history.push({ pathname: "/home" });
  }

  const formatTime = () => {
    return gameOver
      ? `${endTime.minutes < 10 ? 0 : ""}${endTime.minutes}:${endTime.seconds < 10 ? 0 : ""} ${
          endTime.seconds
        }`
      : `${time.minutes < 10 ? 0 : ""}${time.minutes}:${time.seconds < 10 ? 0 : ""}${
          time.seconds
        }`;
  };

  const resetGame = () => {
    setLevel(1);
    setScore(0);
    setTime({ minutes: 0, seconds: 0 });
    setEndTime({ minutes: 0, seconds: 0 });
    setGameOver(false);
    showNextButton(false);
    //setIsStarting(false);
  };

  const goToBookScreen = () => {
    if (isElectron()) {
      history.push({
        pathname: isElectron() ? "/story" : "/",
        storyId: isElectron() ? location.story.id : "",
        story: isElectron() ? location.story : "",
        state: "skip",
      });
    } else setScreen(Layouts.BOOK);
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
          position: "absolute",
          background: `url(${backgroundImage})`,
          backgroundSize: "cover",
        }}
      >
        <div style={{ background: `url(${headerImage})`, padding: "4px" }}>
          <div className="game-header">
            <div className="game-header-left">
              <img
                style={{ marginLeft: "16px", cursor: "pointer" }}
                onClick={() => goToBookScreen()}
                src={homeImage}
                alt=""
              />
            </div>

            <div className="game-header-right">
              <div
                style={{
                  display: "flex",
                  background: `url(${decorator})`,
                  backgroundSize: "cover",
                  width: "24vw",
                }}
              >
                <img src={timeIcon} alt="" style={{ marginLeft: "1vw", marginRight: "2vw" }} />
                <span style={{ fontSize: "4vw", color: "#fff" }}>{formatTime()}</span>
              </div>

              <img
                src={level === 1 ? level1Icon : level === 2 ? level2Icon : level3Icon}
                style={{ marginLeft: "5rem", marginRight: "5rem" }}
                alt=""
              />

              {/* <span className="game-level">{level}</span> */}
              <div
                style={{
                  display: "flex",
                  background: `url(${decorator})`,
                  backgroundSize: "cover",
                  marginRight: "32px",
                  width: "24vw",
                }}
              >
                <img
                  src={scoreIcon}
                  alt=""
                  style={{ marginLeft: "1vw", marginRight: "2vw" }}
                />
                <span style={{ fontSize: "4vw", color: "#fff" }}>{score}</span>
              </div>
            </div>
          </div>
        </div>

        {gameOver ? (
          <GameOverScreen
            goToBookScreen={() => goToBookScreen()}
            resetGame={() => resetGame()}
          />
        ) : (
          (isElectron() || activeStory.assets) && (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingBottom: "12vw",
              }}
            >
              {(!isStarting && (
                <img
                  id="breathing-button"
                  style={{}}
                  src={playIcon}
                  className="play-game-button"
                  onClick={() => setIsStarting(true)}
                />
              )) || (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: level === 1 ? "100px" : level === 2 ? "70px" : "50px",
                    userSelect: "none" as "none",
                  }}
                >
                  <Grid
                    level={level}
                    startTime={new Date().getTime()}
                    onGameOver={onGameOver}
                    onHandleScore={handleScore}
                    story={activeStory}
                    cards={location && location.cards}
                    storyId={storyId}
                    coverImage={
                      isElectron()
                        ? location.story.coverImage
                        : ENDPOINT_URL + "/api/public" + activeStory.data.image_path
                    }
                  />
                  {
                    <img
                      id="breathing-button"
                      style={{ opacity: nextButton ? "1" : "0" }}
                      src={continueIcon}
                      className="next-game-button"
                      onClick={() => onNextGame()}
                    />
                  }
                </div>
              )}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default GameScreen;
