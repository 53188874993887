/* eslint-disable */
import "../assets/css/splashScreen.css";
import introAudio from "../assets/audio/intro.mp3";
import backgroundImage from "../assets/images/splash/background.webp";
import womanKidsImage from "../assets/images/splash/woman_kids.webp";
import titleImage from "../assets/images/splash/title.webp";
import logoImage from "../assets/images/splash/logo.png";

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Layouts } from "../utils/Constants";
import { Howl } from "howler";
import isElectron from "is-electron";

const introPlayer = new Howl({
  src: [introAudio],
  autoplay: true,
  volume: 0.4,
  onplayerror: () => {
    introPlayer.once("unlock", () => introPlayer.play());
  },
});

/**
 * La page de garde (SplashScreen)
 * @component
 * @param props
 * @param props.setScreen {Function} - Une fonction permettant de changer l'écran actuel
 */
function SplashScreen(props) {
  const { setScreen } = props;
  const history = useHistory();

  useEffect(() => {
    introPlayer.play();
    setTimeout(() => {
      introPlayer.stop();
      if (isElectron()) history.push({ pathname: "/home" });
      else setScreen(Layouts.HOME);
    }, 6000);
  }, []);

  const backgroundStyle = {
    // backgroundImage: `url("${backgroundImage}")`,
    position: "absolute" as "absolute",
    top: "0px",
    left: "0px",
    // backgroundSize: "cover",
    // backgroundPosition: "bottom",
    height: "100%",
    width: "100%",
    opacity: 0,
    objectFit: "cover" as "cover",
    objectPosition: "top",
  };

  const womanKidsStyle = {
    // backgroundImage: `url("${womanKidsImage}")`,
    position: "absolute" as "absolute",
    top: "0px",
    left: "0px",
    // backgroundSize: "cover",
    // backgroundPosition: "bottom",
    height: "100%",
    width: "100%",
    opacity: 0,
    objectFit: "cover" as "cover",
    overflow: "unset",
    objectPosition: "top",
  };

  const titleStyle = {
    // backgroundImage: `url("${titleImage}")`,
    position: "absolute" as "absolute",
    top: "0px",
    left: "0px",
    // backgroundSize: "cover",
    // backgroundPosition: "bottom",
    height: "100%",
    width: "100%",
    opacity: 0,
    objectFit: "cover" as "cover",
    objectPosition: "top",
  };

  return (
    <div className="splash-container">
      <img className="fade-in-image-0" style={backgroundStyle} src={backgroundImage} alt="" />
      <img className="fade-in-image-4" style={womanKidsStyle} src={womanKidsImage} alt="" />
      <img className="bouncing-logo" style={titleStyle} src={logoImage} alt="" />
      <img className="bouncing-title" style={titleStyle} src={titleImage} alt="" />
    </div>

    // <div className="splash-container">
    //   <div className="fade-in-image-0" style={backgroundStyle}></div>
    //   <div className="woman-kids fade-in-image-4" style={womanKidsStyle}></div>
    //   <div className="title fade-in-image-3" style={titleStyle}></div>
    // </div>
  );
}

export default SplashScreen;
