/* eslint-disable */
import "../assets/css/bookScreen.css";
import backgroundImage from "./../assets/images/background.webp";
import pageFlip from "./../assets/audio/page_flip.mp3";
import continueIcon from "../assets/images/icons/ic_continue.png";
import React, { useContext, useEffect, useRef, useState } from "react";
import Swiper from "react-id-swiper";
import PagesFactory from "../ui/components/PagesFactory";
import usePages from "../hooks/usePages";
import DictionaryDialog from "../ui/components/DictionaryDialog";
import isElectron from "is-electron";
import Sound from "react-sound";
import ProgressBar from "../ui/components/ProgressBar";
import FloatingButtons from "../ui/components/FloatingButtons";
import StoryContents from "../ui/components/StoryContents";
import { Item } from "../models/item";
import { SettingsContext } from "../hooks/SettingsContext";
import { Howl } from "howler";
import { getIsRead, getLastPage, setIsRead, setLastPage } from "../utils/SharedPreferences";
import PopupDialog from "../ui/components/PopupDialog";
import UnlockGameDialog from "../ui/components/UnlockGameDialog";
import { Layouts } from "../utils/Constants";
import ContinueDialog from "../ui/components/ContinueDialog";

const flipPlayer = new Howl({
  src: [pageFlip],
  autoplay: false,
  volume: 1,
});

/**
 * Le composant qui affiche un conte
 * @component
 * @param props
 * @param props.activeStory - Le conte à afficher
 * @param props.setScreen - La fonction qui permet aux boutons flottants de changer l'ecran
 */
const BookScreen = (props) => {
  const { activeStory, setScreen, prevScreen } = props;
  console.log("activeStory", activeStory);

  const { settings, playSound, isPlaying } = useContext(SettingsContext);

  const [popupContinue, showContinuePopup] = useState(false);

  const [popupGameUnlocked, showUnlockedGamePopup] = useState(false);
  const [gameEnabled, setGameEnabled] = useState(false);
  const [popupGameLocked, showLockedGamePopup] = useState(false);
  const [showActions, setShowActions] = useState(false);

  // Gets weather a story is already read
  const [isStoryRead, setStoryRead] = useState(false);
  useEffect(() => getIsRead(storyId, setStoryRead), []);

  const [autoPlay, setAutoPlay] = useState(isElectron());
  useEffect(() => console.log("autoPlay", autoPlay), [autoPlay]);

  const [fetching, setFetching] = useState(true);
  const [isToggled, setisToggled] = useState(false);
  const [isStoryContentsOpen, setStoryContentsOpen] = useState(false);
  const [currentIndex, updateCurrentIndex] = useState(0);
  const [showDialog, setShowDialog] = useState(false); // show dictionary dialog
  const [dictionary, setDictionary] = useState<any>(); // show dictionary dialog
  const [savedIndex, setSavedIndex] = useState(0);

  const [playStatus, setPlayStatus] = useState(
    autoPlay ? Sound.status.PLAYING : Sound.status.PAUSED
  );

  const [audioPos, setAudioPos] = useState(0);
  const [audioUrl, setAudioUrl] = useState<any>("");

  const swiperRef = useRef<HTMLDivElement>(null);
  const storyId = props.location?.storyId || activeStory.data.id;
  console.log("props.location", props.location);

  const { pages } = usePages(storyId, activeStory?.data || {}, setFetching);

  useEffect(() => {
    console.log("mm== playStatus", playStatus);
    if (
      !isStoryRead &&
      currentIndex === pages.length - 1 &&
      playStatus !== Sound.status.PLAYING
    )
      setTimeout(() => showUnlockedGamePopup(true), 1000);
  }, [currentIndex]);

  const params = {
    on: {
      slideChange: (index) => {
        if (swiperRef && swiperRef.current) {
          let realIndex = swiperRef.current["swiper"].realIndex;
          flipPlayer.play();
          updateCurrentIndex(realIndex);
          setLastPage(storyId, realIndex);

          if (realIndex === pages.length - 1) {
            setIsRead(storyId, true);
            setStoryRead(true);
            // if(!Sound.status.PLAYING) setTimeout(()=> showUnlockedGamePopup(true), 1000);
            // console.log("mm== playStatus", playStatus);
            // console.log("mm== autoPlay", autoPlay);
          }

          // if(autoPlay && !isStoryRead && realIndex === pages.length - 1)
          //   setTimeout(()=> showUnlockedGamePopup(true), 400);
        }
      },
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
      spaceBetween: 10,
    },
  };

  let storyAssets =
    props.location && props.location.aboutProps
      ? props.location.aboutProps.obj
      : { pages: [] };

  useEffect(() => {
    console.log("ttt storyId", storyId);
    getLastPage(storyId, (pageIndex) => {
      console.log("ttt pageIndex", pageIndex);
      if (pageIndex && pageIndex > 0) {
        if (
          prevScreen === Layouts.SETTINGS ||
          prevScreen === Layouts.GAME ||
          (props.location && props.location.state === "skip")
        ) {
          updateCurrentIndex(pageIndex);
        } else {
          setSavedIndex(pageIndex);
          showContinuePopup(true);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (audioUrl === null && autoPlay) updateCurrentIndex((prev) => prev + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioUrl]);

  useEffect(() => {
    swipeToPage(currentIndex);
  }, [currentIndex]);

  useEffect(() => {
    if (!isPlaying() && settings.playing) playSound();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settings]);

  useEffect(() => {
    window.onload = () => window.history.back();
  }, []);

  useEffect(() => {
    setAutoPlay(playStatus !== Sound.status.STOPPED && playStatus !== Sound.status.PAUSED);
  }, [playStatus]);

  const handleFinishedPlaying = () => {
    if (currentIndex < pages.length - 1 && autoPlay) setAudioUrl(null);
    else setPlayStatus(Sound.status.STOPPED);

    // Show a popup saying the game was unlocked
    if (currentIndex === pages.length - 1 && Sound.status.STOPPED)
      setTimeout(() => showUnlockedGamePopup(true), 600);
  };

  const handleItemClicked = (item: Item) => {
    if (isToggled && item.dictionary && item.dictionary.id) {
      // const dictionary = { ...item.dictionary, item };
      setDictionary({ ...item.dictionary, item });
      if (playStatus === Sound.status.PLAYING) {
        setPlayStatus(Sound.status.PAUSED);
        setShowDialog(true);
      } else {
        setShowDialog(true);
      }
    } else {
      setAudioPos(item.start_time ? item.start_time : 1);
      handlePlay();
      // if (playStatus === Sound.status.PAUSED || playStatus === Sound.status.STOPPED) {
      //   setAutoPlay(true);
      //   setPlayStatus(Sound.status.PLAYING);
      // }
    }

    // if (isElectron()) {
    //   if (item.dictionary !== undefined && isToggled) {
    //     setDictionary({ ...item.dictionary, item });
    //     setShowDialog(true);
    //   }
    // }
  };

  const swipeToPage = (index) => {
    if (swiperRef && swiperRef.current) {
      swiperRef.current["swiper"].slideTo(index);
      setStoryContentsOpen(false);
    }
  };

  const handleOnPlaying = (player) => {
    setPlayStatus(Sound.status.PLAYING);
    setAudioPos(player.position);
  };

  const handleOnPausing = (player) => {
    setPlayStatus(Sound.status.PAUSED);
    // setAudioPos(player.position);
  };

  function handlePlay() {
    setPlayStatus((prev) => {
      if (prev === Sound.status.STOPPED || prev === Sound.status.PAUSED) {
        setAutoPlay(true);
        console.log("handlePlay autoPlay: ", autoPlay);
        return Sound.status.PLAYING;
      } else {
        setAutoPlay(false);
        console.log("handlePlay autoPlay: ", autoPlay);
        return Sound.status.PAUSED;
      }
    });
  }

  const showSavedPage = () => {
    showContinuePopup(false);
    updateCurrentIndex(savedIndex);
  };

  const resetSavedPage = () => {
    showContinuePopup(false);
    setLastPage(storyId, 0);
  };

  const onClickAway = (e) => {
    e.stopPropagation();
    console.log("iii e.target.className", e.target.className);
    const targetCN = (e.target as HTMLDivElement).className;
    if (
      targetCN !== "textTemplate2" &&
      targetCN !== "swiper-button-prev" &&
      targetCN !== "swiper-button-prev swiper-button-disabled" &&
      targetCN !== "swiper-button-next" &&
      targetCN !== "swiper-button-next swiper-button-disabled" &&
      !targetCN.includes("floatingButton")
    ) {
      setShowActions(!showActions);
    }
  };
  return (
    <div className="book-container" onClick={(e) => onClickAway(e)}>
      {popupContinue && (
        <ContinueDialog
          showDialog={popupContinue}
          setShowDialog={() => resetSavedPage()}
          onValidate={() => showSavedPage()}
          obj={activeStory?.assets}
          page1={pages[0]}
          page2={pages[savedIndex]}
        />
      )}

      {isElectron()
        ? !fetching && (
            <div
              className="book-container"
              style={{
                fontFamily: `tifinagh${
                  settings.fontType !== undefined
                    ? settings.fontType
                    : props.location.story.font_path
                }`,
                height: "100%",
                width: "100%",
                position: "absolute",
                background: `url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <StoryContents
                pages={pages}
                isOpen={isStoryContentsOpen}
                setOpen={setStoryContentsOpen}
                storyAsObject={storyAssets}
                swipeToPage={swipeToPage}
                currentPageIndex={currentIndex}
              />

              {audioUrl !== undefined && (
                <Sound
                  url={audioUrl}
                  playStatus={playStatus}
                  onPlaying={(player) => handleOnPlaying(player)}
                  onPause={(player) => handleOnPausing(player)}
                  position={audioPos}
                  onFinishedPlaying={handleFinishedPlaying}
                  // updateInterval={50}
                />
              )}

              {dictionary && showDialog && (
                <DictionaryDialog
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                  page={pages[currentIndex]}
                  obj={storyAssets}
                  isToggled={isToggled}
                  dictionary={dictionary}
                />
              )}

              {/* <ProgressBar
                  template={pages[currentIndex].template}
                  numPage={currentIndex + 1}
                  nbPages={pages.length}
                /> */}

              <FloatingButtons
                isToggled={isToggled}
                setisToggled={setisToggled}
                setPlayStatus={setPlayStatus}
                audioHandler={handlePlay}
                page={pages[currentIndex]}
                obj={undefined}
                storyId={storyId}
                isStoryContentsOpen={isStoryContentsOpen}
                setStoryContentsOpen={setStoryContentsOpen}
                playStatus={playStatus}
                story={props.location.story}
                isGameActive={isStoryRead}
                showLockedGamePopup={showLockedGamePopup}
                storyCards={props.location.story.cards}
                show={showActions}
                setShow={setShowActions}
              />

              {pages.length > 0 && (
                <div className="book-reader-container" style={{ height: "100%" }}>
                  <Swiper {...params} ref={swiperRef} activeSlideKey={`page${currentIndex}`}>
                    {pages.map((res, index) => {
                      return (
                        <section
                          key={"page" + index}
                          style={{ height: "100%", width: "100%" }}
                        >
                          <PagesFactory
                            key={index}
                            page={res}
                            isToggled={isToggled}
                            handleItemClicked={handleItemClicked}
                            audioPos={audioPos}
                            currentPageId={pages[currentIndex].id}
                            setAudioURL={setAudioUrl}
                            setAudioPos={setAudioPos}
                            currentPageNumber={currentIndex + 1}
                            totalPages={pages.length}
                          />
                        </section>
                      );
                    })}
                  </Swiper>
                </div>
              )}
            </div>
          )
        : !fetching && (
            <div
              className="book-container"
              style={{
                fontFamily: `tifinagh${
                  settings.fontType !== undefined
                    ? settings.fontType
                    : activeStory.data.font_path
                }`,
                height: "100%",
                width: "100%",
                position: "absolute",
                background: `url(${backgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <StoryContents
                pages={pages}
                isOpen={isStoryContentsOpen}
                setOpen={setStoryContentsOpen}
                storyAsObject={activeStory.assets}
                swipeToPage={swipeToPage}
                currentPageIndex={currentIndex}
              />

              <FloatingButtons
                isToggled={isToggled}
                setisToggled={setisToggled}
                setPlayStatus={setPlayStatus}
                audioHandler={handlePlay}
                page={pages[currentIndex]}
                obj={activeStory.assets}
                storyId={storyId}
                isStoryContentsOpen={isStoryContentsOpen}
                setStoryContentsOpen={setStoryContentsOpen}
                playStatus={playStatus}
                story={props.location ? props.location.story : activeStory.data}
                isGameActive={isStoryRead}
                showLockedGamePopup={showLockedGamePopup}
                audioUrl={audioUrl}
                setAudioUrl={setAudioUrl}
                setScreen={setScreen}
                show={showActions}
                setShow={setShowActions}
              />
              {pages.length > 0 && (
                <div className="book-reader-container" style={{ height: "100%" }}>
                  {/* <ProgressBar
                      template={pages && pages[currentIndex] ? pages[currentIndex].template : 1}
                      currentPageNumber={currentIndex + 1}
                      totalPages={pages.length}
                    /> */}

                  <Swiper {...params} ref={swiperRef} activeSlideKey={`${currentIndex}`}>
                    {pages.map((page, index) => {
                      return (
                        <section key={index} style={{ height: "100%", width: "100%" }}>
                          <PagesFactory
                            key={index}
                            page={page}
                            storyAssets={activeStory.assets}
                            isToggled={isToggled}
                            audioPos={audioPos}
                            handleItemClicked={handleItemClicked}
                            style={{ fontSize: `${settings.fontSize}vw` }}
                            currentPageId={pages[currentIndex].id}
                            setAudioURL={setAudioUrl}
                            setAudioPos={setAudioPos}
                            currentPageNumber={currentIndex + 1}
                            totalPages={pages.length}
                          />
                        </section>
                      );
                    })}
                  </Swiper>
                </div>
              )}
              {audioUrl !== undefined && (
                <Sound
                  url={audioUrl}
                  playStatus={playStatus}
                  onPlaying={(player) => handleOnPlaying(player)}
                  onPause={(player) => handleOnPausing(player)}
                  position={audioPos}
                  onFinishedPlaying={handleFinishedPlaying}
                  // updateInterval={50}
                />
              )}

              {dictionary && showDialog && (
                <DictionaryDialog
                  showDialog={showDialog}
                  setShowDialog={setShowDialog}
                  page={pages[currentIndex]}
                  obj={activeStory.assets}
                  isToggled={isToggled}
                  dictionary={dictionary}
                />
              )}
            </div>
          )}

      {/**popupGameUnlocked && (
        <PopupDialog
          body={
            <p style={{ color: "white", fontSize: "22px" }}>
              Félicitation!! Vous avez débloqué le jeu de ce conte
            </p>
          }
          onValidate={() => showUnlockedGamePopup(false)}
          showDialog={popupGameUnlocked}
        />
      )}
      )*/}
      {popupGameLocked && (
        <UnlockGameDialog
          showDialog={popupGameLocked}
          setShowDialog={showLockedGamePopup}
          obj={activeStory?.assets}
          page={pages[pages.length - 1]}
        />
      )}
    </div>
  );
};

export default BookScreen;
