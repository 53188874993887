/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Howl } from "howler";
import "../assets/css/gameOverScreen.css";
import winSound from "../assets/audio/cheering.mp3";
import fireworksSound from "../assets/audio/fireworks.mp3";
import PopupDialog from "../ui/components/PopupDialog";
import retryIcon from "../assets/images/icons/ic_restart.png"

const winPlayer = new Howl({
		src: [winSound],
		autoplay: false,
		volume: 0.4,
	});

const fireworksPlayer = new Howl({
	src: [fireworksSound],
	autoplay: false,
	loop: true,
	volume: 0.4,
});

const GameOverScreen = (props) => {
	const { resetGame, goToBookScreen } = props;

	const [popupShown, showPopup] = useState(true);

	useEffect(()=> { 
		winPlayer.play();
		fireworksPlayer.play();
}, []);

	return (
		<div>
			<PopupDialog
				body={
					<p style={{ color: "white", fontSize: "22px", textAlign: "center" }}>
						ⴰⵢⵢⵓⵣ
					</p>
				}
				noOverlay={true}
				sticky={true}
				onValidate={() => {
					fireworksPlayer.stop();
					goToBookScreen()
				}}
				cancelIcon={retryIcon}
				onCancel={()=> {
					fireworksPlayer.stop();
					resetGame()
				}}
				showDialog={popupShown}
			/>
			<div className="ball">
				<div className="pyro">
					<div className="before"></div>
					<div className="after"></div>
				</div>
				
				{/* <p className="winning-text">Félicitations vous avez gagné</p> */}
				<div className="balloons-canvas">
					<div className="balloon"></div>
					<div className="balloon"></div>
					<div className="balloon"></div>
					<div className="balloon"></div>
					<div className="balloon"></div>
				</div>
			</div>
		</div>
		
	)
}

export default GameOverScreen;