import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fr from "./fr.json";
import ar from "./ar.json";
import en from "./en.json";
import am from "./am.json";

i18n.use(initReactI18next).init({
  // lng: I18nManager.isRTL ? 'ar' : 'fr',
  fallbackLng: "fr",
  resources: {
    ar: ar,
    fr: fr,
    en: en,
    am: am,
  },
  interpolation: {
    escapeValue: false,
  },
  keySeparator: false,
  compatibilityJSON: "v3",
});

export default i18n;
