/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "../../assets/css/floatingButtons.css";
// import arrowUp from '../../assets/images/arrow_up.png';
import gameIcon from "../../assets/images/icons/ic_game.webp";
import pullUpIcon from "../../assets/images/icons/ic_up.png";
import pullDownIcon from "../../assets/images/icons/ic_down.png";
// import pullUpIcon from "../../assets/images/icons/ic_pull_up.webp";
// import pullDownIcon from "../../assets/images/icons/ic_pull_down.webp";
import separator1 from "../../assets/images/bar/separators/separator1.webp";
import separator2 from "../../assets/images/bar/separators/separator2.webp";
import separator3 from "../../assets/images/bar/separators/separator3.webp";
import separator4 from "../../assets/images/bar/separators/separator4.webp";
import separator5 from "../../assets/images/bar/separators/separator5.webp";
import separator6 from "../../assets/images/bar/separators/separator6.webp";
import homePNG from "../../assets/images/bar/buttons/home.webp";
import dictionary from "../../assets/images/bar/buttons/dictionary.webp";
import dictionaryOn from "../../assets/images/bar/buttons/dictionaryOn.webp";
import menu from "../../assets/images/bar/buttons/menu.webp";
import settings from "../../assets/images/bar/buttons/settings.webp";
import playPause from "../../assets/images/bar/buttons/playPause.webp";
import pauseButton from "../../assets/images/bar/buttons/pauseButton.webp";
import Sound from "react-sound";
import isElectron from "is-electron";
import { Layouts } from "../../utils/Constants";
import PopupDialog from "./PopupDialog";
import UnlockGameDialog from './UnlockGameDialog';

// const styles = {
//   separator: {
//     height: "1rem",
//     transition: "all 1s ease-in-out",
//   },
//   barButton: {
//     height: "5rem",
//     cursor: "pointer",
//     transition: "all 1s ease-in-out",
//   },
//   showHideBtn: {
//     opacity: "0",
//   },
// };

/**
 * Composant pour les Boutons flottants
 * @param props 
 * @returns 
 */
const FloatingButtons = (props) => {
  const { audioHandler, isStoryContentsOpen, setStoryContentsOpen, setScreen, isGameActive, obj, lastPage, show, setShow } = props;

  // const [show, setShow] = useState(true);
  const history = useHistory();
  // const [rotate, setRotate] = useState(180);

  const [translate, setTranslate] = useState(6);
  const [btnOpacity, setBtnOpacity] = useState(0);
  const [pullIcon, setPullIcon] = useState(pullDownIcon);

  useEffect(()=> {
    setTranslate(show ? 0 : 6);
    setBtnOpacity(show ? 1 : 0);
  }, [show]);

  let url_audio = "";
  if (props.obj !== undefined) {
    if (
      props.obj !== undefined &&
      props.page !== undefined &&
      Object.keys(props.obj).length !== 0 &&
      props.obj[Object.keys(props.obj)[0]].pages[props.page.id]["audio.mp3"]
    ) {
      // console.log(props.obj[Object.keys(props.obj)[0]]);
      // url_audio = URL.createObjectURL(
      //   props.obj[Object.keys(props.obj)[0]].pages[props.page.id]["audio.mp3"]
      // );
      const blob = new Blob([props.obj[Object.keys(props.obj)[0]].pages[props.page.id]["audio.mp3"]], {type: "audio/mpeg"});
      url_audio = URL.createObjectURL(blob);
    }
  }
  useEffect(() => {
    if (translate === 6) {
    }
  }, [translate]);

  function handleHome() {
    if (isElectron()) history.push({ pathname: "/home" });
    else setScreen(Layouts.HOME);
  }

  function handlePlay() {
    audioHandler();
    // if (isElectron()) audioHandler();
    // else {
    //   if (props.playStatus === Sound.status.STOPPED) {
    //     props.setAudioUrl(url_audio);
    //   }
    //   props.setPlayStatus((prev) =>
    //     prev === Sound.status.STOPPED || prev === Sound.status.PAUSED
    //       ? Sound.status.PLAYING
    //       : Sound.status.PAUSED
    //   );
    // }
  }

  function handleDictionary() {
    props.setisToggled(!props.isToggled);
  }

  function handleBarDisplay() {
    setShow(!show)
    //setTranslate((prev) => (prev === 6 ? 0 : 6));
    //setBtnOpacity((old_opacity) => (old_opacity === 0 ? 1 : 0));
    //setPullIcon((prevIcon) => (prevIcon === pullDownIcon ? pullUpIcon : pullDownIcon));
  }

  function handleMenu() {
    setStoryContentsOpen((old_value) => !old_value);
  }

  function handleGame() {
    if(isGameActive) {
      if (isElectron())
      history.push({
        pathname: "/game",
        aboutProps: { object: props.obj },
        storyId: props.storyId,
        cards: props.storyCards,
        story: props.story,
      });
    else setScreen(Layouts.GAME);
    }
    else props.showLockedGamePopup(true)
  }

  function handleSettings() {
    if (isElectron())
      history.push({
        pathname: "/settings",
        story: props.story,
      });
    else setScreen(Layouts.SETTINGS);
  }

  return (
    <>
      <div className="floatingButtonsContainer">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            transform: `translateY(${translate / 2}rem)`,
          }}
        >
          <img className="separator" src={separator1} alt="" />

          {/* Home */}
          <img
            className="floatingButton"
            style={{ opacity: btnOpacity }}
            onClick={() => handleHome()}
            src={homePNG}
            alt=""
          />
          <img className="separator" src={separator2} alt="" />

          {/* Play */}
          <img
            className="floatingButton"
            style={{ opacity: btnOpacity }}
            onClick={() => handlePlay()}
            src={props.playStatus === Sound.status.PLAYING ? pauseButton : playPause}
            alt=""
          />
          <img className="separator" src={separator3} alt="" />

          {/* Dictionary */}
          <img
            className="floatingButton"
            style={{ opacity: btnOpacity }}
            onClick={() => handleDictionary()}
            src={props.isToggled ? dictionaryOn : dictionary}
            alt=""
          />
          <img className="separator" src={separator4} alt="" />

          {/* Show / Hide */}
          <div className="showHideBtnContainer">
            <img
              className="floatingButton showHideBtn"
              style={{ opacity: "0" }}
              src={translate ? pullUpIcon : pullDownIcon}
              alt=""
              onClick={() => handleBarDisplay()}
            />
          </div>
          <img className="separator" src={separator4} alt="" />

          {/* Menu */}
          <img
            className="floatingButton"
            style={{ opacity: btnOpacity }}
            onClick={() => handleMenu()}
            src={menu}
            alt=""
          />
          <img className="separator" src={separator5} alt="" />

          {/* Game */}
          <img
            className={`floatingButton ${isGameActive ? "active" : "inactive"}`}
            style={{ opacity: isGameActive ? btnOpacity : btnOpacity / 2 }}
            onClick={() => handleGame()}
            src={gameIcon}
            alt=""
          />
          <img className="separator" src={separator6} alt="" />

          {/* Settings */}
          <img
            className="floatingButton"
            style={{ opacity: btnOpacity }}
            src={settings}
            alt=""
            onClick={handleSettings}
          />
          <img className="separator" src={separator5} alt="" />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            position: "absolute",
            transform: `translateY(${translate / 2}rem)`,
            zIndex: -1,
          }}
        >
          <img className="floatingButton showHideBtn" src={translate ? pullUpIcon : pullDownIcon} alt="" />
        </div>
      </div>
    </>
  );
};

export default FloatingButtons;
