/* eslint-disable */
import isElectron from "is-electron";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import React, { createRef, useContext, useEffect, useRef, useState } from "react";
import "../../assets/css/StoryContents.css";
import Scrollable from "./Scrollable";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

/**
 * Le sommaire
 * @component
 * @param props
 * @param props.pages {Page[]} - Les pages qui s'afficheront dans le sommaire
 * @param props.storyAsObject {Object}- L'object contenant les ressources du conte
 * @param props.isOpen {boolean} - Un booléen contrôlant la visibilité du sommaire
 * @param props.setOpen {Function} - Une fonction permettant de changer la visibilité du sommaire
 *
 */
const StoryContents = (props) => {
  const { pages, storyAsObject, isOpen, setOpen, currentPageIndex } = props;

  const restorePosition = ({ getItemById, scrollToItem }: scrollVisibilityApiType) => {
    scrollToItem(getItemById(`item${currentPageIndex}`), "auto");
  };

  return isOpen ? (
    <div
      className="story-content-container"
      onClick={(e) => {
        e.stopPropagation();
        const shouldClose =
          (e.target as HTMLDivElement).className === "story-content-container";
        if (isOpen && shouldClose) setOpen(false);
      }}
    >
      <div className="pages-container addons">
        <ScrollMenu LeftArrow={LeftArrow} RightArrow={RightArrow} onInit={restorePosition}>
          {pages.map((page, index) => (
            <StoryContentsItem
              index={index}
              key={`item${index}`}
              itemId={`item${index}`}
              imgPath={
                !isElectron()
                  ? URL.createObjectURL(
                      storyAsObject[Object.keys(storyAsObject)[0]].pages[page.id][
                        "illustration.png"
                      ]
                    )
                  : page.image
              }
              swipeToPage={props.swipeToPage}
              page={page}
              style={{ border: currentPageIndex === index ? "solid" : "none" }}
            />
          ))}
        </ScrollMenu>

        {/* <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={(e) => {}} />

        <Scrollable className="addons">
          {pages.map((page, index) => (
            <StoryContentsItem
              index={index}
              imgPath={
                !isElectron()
                  ? URL.createObjectURL(
                      storyAsObject[Object.keys(storyAsObject)[0]].pages[page.id][
                        "illustration.png"
                      ]
                    )
                  : page.image
              }
              swipeToPage={props.swipeToPage}
              page={page}
            />
          ))}
        </Scrollable>

        <ArrowForwardIosIcon style={{ cursor: "pointer" }} onClick={(e) => {}} /> */}
      </div>
    </div>
  ) : null;
};

function LeftArrow() {
  const { scrollPrev } = useContext(VisibilityContext);

  return (
    <ArrowBackIosIcon
      onClick={() => scrollPrev()}
      style={{ height: "auto", margin: "6px", cursor: "pointer" }}
    />
  );
}

function RightArrow() {
  const { scrollNext } = useContext(VisibilityContext);

  return (
    <ArrowForwardIosIcon
      onClick={() => scrollNext()}
      style={{ height: "auto", margin: "6px", cursor: "pointer" }}
    />
  );
}

const StoryContentsItem = (props) => {
  const { imgPath, index, swipeToPage, itemId, style } = props;
  const visibility = React.useContext(VisibilityContext);
  const visible =
    !visibility.initComplete || (visibility.initComplete && visibility.isItemVisible(itemId));

  return (
    <div
      data-cy={itemId}
      className="story-content-img-container"
      style={{ height: "inherit", ...style }}
    >
      <img
        src={imgPath}
        alt=""
        className="story-content-img"
        draggable={false}
        onClick={() => swipeToPage(index)}
      />
      <div className="story-item-number">
        <span className="story-item-number-span">{index + 1}</span>
      </div>
    </div>
  );
};

export default StoryContents;
