import "./assets/css/App.css";
import bgAudio from "./assets/audio/sway_ocb_relax.mp3";

import React, { useEffect, useState } from "react";
import { Route, Switch, HashRouter } from "react-router-dom";
import BookScreen from "./containers/BookScreen";
import HomeScreen from "./containers/HomeScreen";
import GameScreen from "./containers/GameScreen";
import HomeSettingsScreen from "./containers/HomeSettingsScreen";
import SettingsScreen from "./containers/SettingsScreen";
import { SettingsContext } from "./hooks/SettingsContext";
import { FontSizes } from "./utils/Constants";
import { Howl } from "howler";
import { Layouts } from "./utils/Constants";
import SplashScreen from "./containers/SplashScreen";
import isElectron from "is-electron";
import { Settings } from "./models/Settings";
import firebase from "./services/fcm";
import HomeService from "./services/home.service";

const audioPlayer = new Howl({
  src: [bgAudio],
  autoplay: false,
  loop: true,
  volume: 0.15,
  onplayerror: () => {
    audioPlayer.once("unlock", () => audioPlayer.play());
  },
});

const defaultSettings: Settings = {
  fontType: undefined,
  fontSize: FontSizes.FONT_MEDIUM,
  playing: true,
  autoPlay: false,
};

function App() {
  const [screen, setScreen] = useState<string>(Layouts.SPLASH);
  const [settings, setSettings] = useState<Settings>(defaultSettings);

  const [sessionStories, setSessionStories] = useState<any[]>([]);
  const [selectdStory, setSelectdStory] = useState(0);
  const [lang, setLang] = useState("fr");
  const [prevScreen, setPrevScreen] = useState<string>();

  useEffect(() => {
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      messaging
        .requestPermission()
        .then(() => {
          return messaging.getToken();
        })
        .then((token) => {
          console.log("token", token);

          HomeService.getInstance()
            .subscribeToTopic(token)
            .then((res) => console.log("fcm subscription to topic: ", res));
        })
        .catch((err) => console.log(err));

      messaging.onMessage((payload) => {
        console.log("App.js payload: ", payload);
        navigator.serviceWorker.ready.then((registration) => {
          const title = payload.notification.title;
          const options = {
            body: payload.notification.body,
            icon: payload.notification.image,
            actions: [
              {
                action: payload.notification.click_action,
                title: "ⵜⵉⵏⴼⵓⵙⵉⵏ ⵉⵏⵓ",
              },
            ],
            data: payload.data,
          };
          if (registration) {
            registration.showNotification(title, options);
          }
        });
      });
    }
  }, []);

  // useEffect(() => console.log("lllscreen", screen), [screen]);
  // useEffect(() => console.log("lllprevScreen", prevScreen), [prevScreen]);

  const playSound = () => audioPlayer.play();
  const stopSound = () => audioPlayer.stop();
  const isPlaying = () => audioPlayer.playing();

  /**
   * Une méthode permettant de changer l'écran
   * @param screen - Ecran à afficher
   * @param storyId - Un identifiant optionnel permettant d'identifier le conte
   */
  const setDisplayScreen = (nextScreen, storyId?) => {
    if (storyId) {
      setSelectdStory(getStoryIndexById(storyId));
    }
    setPrevScreen(screen.slice());
    setScreen(nextScreen);
  };

  const setSessionStory = (story) => {
    // console.log("setSessionStory(story)", story);

    if (story) {
      let storyIndex = storyAlreadyInSession(story);
      if (storyIndex !== undefined) {
        setSelectdStory(storyIndex);
      } else {
        let tempStories = [...sessionStories];
        tempStories[tempStories.length] = story;
        setSessionStories(tempStories);
        setSelectdStory(tempStories.length - 1);
      }
    }
  };

  const storyAlreadyInSession = (story) => {
    let storyIndex;
    if (sessionStories.length > 0)
      sessionStories.forEach((sessionStory, index) => {
        if (sessionStory.data.id === story.data.id) {
          storyIndex = index;
          return;
        }
      });
    return storyIndex;
  };

  const getStoryIndexById = (storyId) => {
    let storyIndex = 0;
    if (sessionStories.length > 0)
      sessionStories.forEach((sessionStory, index) => {
        if (sessionStory.data.id === storyId) {
          storyIndex = index;
          return;
        }
      });
    return storyIndex;
  };

  return (
    <SettingsContext.Provider
      value={{ lang, setLang, settings, setSettings, playSound, stopSound, isPlaying }}
    >
      <div style={{ height: "100%", width: "100%", position: "absolute" }}>
        {isElectron() ? (
          <HashRouter>
            <Switch>
              {/* <Redirect exact from="/" to="/home" /> */}
              <Route exact path="/" component={SplashScreen} />
              <Route exact path="/home" component={HomeScreen} />
              <Route exact path="/home-settings" component={HomeSettingsScreen} />
              <Route path="/story" component={BookScreen} />
              <Route path="/game" component={GameScreen} />
              <Route path="/settings" component={SettingsScreen} />
            </Switch>
          </HashRouter>
        ) : screen === Layouts.SPLASH ? (
          <SplashScreen setScreen={setDisplayScreen} />
        ) : screen === Layouts.HOME ? (
          <HomeScreen
            setScreen={setDisplayScreen}
            setSessionStory={setSessionStory}
            sessionStories={sessionStories}
          />
        ) : screen === Layouts.HOME_SETTINGS ? (
          <HomeSettingsScreen setScreen={setDisplayScreen} />
        ) : screen === Layouts.BOOK ? (
          <BookScreen
            setScreen={setDisplayScreen}
            prevScreen={prevScreen}
            activeStory={sessionStories[selectdStory]}
          />
        ) : screen === Layouts.GAME ? (
          <GameScreen
            setScreen={setDisplayScreen}
            activeStory={sessionStories[selectdStory]}
          />
        ) : screen === Layouts.SETTINGS ? (
          <SettingsScreen
            setScreen={setDisplayScreen}
            activeStory={sessionStories[selectdStory]}
          />
        ) : (
          <></>
        )}
      </div>
    </SettingsContext.Provider>
  );
}

export default App;
