/* eslint-disable */
import "../../assets/css/card.css";
import "../../assets/css/scrollable.css";
import React, { useEffect, useState } from "react";
import HomeService from "../../services/home.service";
import { ENDPOINT_URL } from "../../utils/Constants";
import downloadButton from "./../../assets/images/download.png";
import starIcon from "./../../assets/images/icons/ic_new.png";
// import starIcon from "./../../assets/images/icons/ic_star.png";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import isElectron from "is-electron";
import axios from "axios";
import { BallClipRotate } from "react-pure-loaders";

const URL_PREFIX = isElectron() ? ENDPOINT_URL : "";

/**
 * C'est le composant qui affiche la miniature d'un conte dans la page d'accueil
 * @component
 * @param props
 * @param props.story {Object} - Le conte concerné par la miniature
 * @param props.assets {Object} - Les ressources du conte
 * @param props.existsLocally {boolean} - Un boolean déterminant si le conte est déjà téléchargé ou pas
 * @param props.handleOpen {Function} - Une fonction qui définit le traitement d'ouverture d'un conte
 * @param props.handleSave {Function} - Une fonction qui définit le traitement d'enregistrement d'un conte
 * @param props.handleDownload {Function} - Une fonction qui définit le traitement de téléchargement d'un conte
 */
const Card = (props) => {
  const { className, story, assets, existsLocally, handleOpen, handleSave, handleDownload } = props;

  // Percentage of progress bar
  const [percentage, setPercentage] = useState(0);
  const [disable, setdisable] = useState(false);
  const [storyData, setStoryData] = useState(story);
  const [storyAssets, setStoryAssets] = useState(assets || {});

  // const [showProgressbar, setShowProgressBar] = useState(false);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  const [accessible, setAccessible] = useState(true);

  // If storyAssets empty download story else open it
  const content = Object.keys(storyAssets).length === 0 ? "Download" : "Open";
  const imageSrc =
    isElectron() && existsLocally
      ? story.coverImage
      : URL_PREFIX + "/api/public" + story.image_path;

  useEffect(() => {
    if (
      !isElectron() &&
      !assets &&
      Object.keys(storyData).length > 0 &&
      Object.keys(storyAssets).length > 0
    ) {
      handleSave({ data: storyData, assets: storyAssets });
    }
  }, [storyData, storyAssets]);

  useEffect(() => {
    if (isElectron()) {
      axios
        .get(URL_PREFIX + "/api/story/" + story.id)
        .then((res) => {
          // console.log(`downloading (dv: ${res.data.version}, sv: ${story.version}) => /api/story/` + story.id, res);
          setUpdateAvailable(res.data.version > story.version);
          setAccessible(res.data.version <= story.version);
        })
        .catch((err) => console.log(err));

      window["api"].receive("progress", (data) => {
        let [progress, storyId] = data.split("+");

        console.log("progress:", progress);

        if (parseInt(storyId) === parseInt(story.id)) {
          setPercentage(Math.round((progress / 4) * 100));
        }
      });
    }
  }, []);

  /**
   * Cette fonction s'occupe du téléchargement du conte lorsque l'utilisateur clique sur le bouton de téléchargement,
   * elle fait aussi de la mise à jour du conte si une mise à jour est disponible
   * @method
   * @param story - Un objet permettant de déterminer le conte concerné par le traitement
   */
  const handleDownloadStory = (story) => {
    if (isElectron() && handleDownload) {
      if (isUpdateAvailable) {
        window["api"].send("updateStory", story.id);
        setUpdateAvailable(false);
      } else {
        handleDownload(story.id, story);
      }
    } else if (!disable) {
      if (content === "Download") {
        setdisable(true);
        HomeService.getInstance()
          .getStoryById(story.id)
          .then((result) => setStoryData(result))
          .then(() => HomeService.getInstance().downloadZipFile(story.id, setPercentage))
          .then((result) => setStoryAssets(result))
          .then(() => setdisable(false))
          .catch((error) => console.error(error));
      }
    }
  };

  function handleOpenStory() {
    if (!isElectron() || existsLocally) {
      if (isElectron()) {
        if (isUpdateAvailable) {
          window["api"].send("updateStory", story.id);
          setUpdateAvailable(false);
        } else if (accessible) handleOpen(story.id, storyData);
      } else if (!disable) {
        if (content !== "Download") {
          handleOpen(story.id, storyData);
        }
      }
    }
  }

  useEffect(() => {
    if (percentage === 100) {
      setTimeout(() => {
        setPercentage(0);
        setAccessible(true);
      }, 1000);
    }
  }, [percentage]);


  const styles = {
    cardContainer: {
      display: "flex",
      borderStyle: "solid",
      borderWidth: isUpdateAvailable ? "4px" : "2px",
      borderColor: isUpdateAvailable ? "green" : "white",
      borderRadius: "50%",
      backgroundColor: "yellow",
      overflow: "hidden",
      margin: "0 40px 0 40px",
      position: "relative" as "relative",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "0px 2px 10px black",
      cursor: content !== "Download" ? "pointer" : "",
    },
    cardImage: {
      backgroundColor: "rgba(85,236,248,255)",
      position: "absolute" as "absolute",
      height: "100%",
      width: "100%",
      zIndex: 0,
      filter: isElectron()
        ? existsLocally
          ? ""
          : "brightness(75%)"
        : content === "Download" && !disable
          ? "brightness(75%)"
          : "",
    },
    imageEffect: {
      zIndex: 1,
      borderRadius: "50%",
      position: "absolute" as "absolute",
      // backgroundImage:
      //   "linear-gradient(190deg, rgba(255, 255, 255, 0.5), 10%, rgba(0, 0, 0, 0))",
      height: "89%",
      width: "89%",
    },
    progressBar: {
      zIndex: 1,
      borderRadius: "50%",
      position: "absolute" as "absolute",
      backgroundImage: "rgba(0, 0, 0, 0.5)",
      height: "105%",
      width: "105%",
    },
    textStyle: {
      display:
        content === "Download" && !disable ? "none" : "" || percentage === 0 ? "none" : "",
      zIndex: 2,
      marginTop: "-70%",
      color: "white",
      fontSize: 15,
    },
    downloadButton: {
      display: isElectron() ? existsLocally ? isUpdateAvailable ? "" : "none" : percentage > 0 ? "none" : "" : content === "Download" && !disable ? "" : "none",
      zIndex: 2,
      width: "15%",
      marginTop: "-70%",
      cursor: "pointer",
    },
    starImgContainer: {
      //  display: isElectron()
      //   ? existsLocally
      //     ? "none"
      //     : percentage > 0
      //     ? "none"
      //     : ""
      //   : content === "Download" && !disable
      //   ? ""
      //   : "none",
      display: story.is_new && content === "Download" && !disable ? "flex" : "none",
      alignItems: "flex-start",
      justifyContent: "flex-end",
    },
    // starImg: {
    //   width: 40,
    //   zIndex: 2,
    //   marginTop: "-71%",
    //   marginRight: "18%",
    // }
    starImg: {
      width: 60,
      zIndex: 2,
      marginTop: "-75%",
      marginRight: "18%",
    },
    updateAvailableBadge: {
      cursor: "pointer",
      backgroundColor: "orange",
      height: "30px",
      width: "30px",
      position: "absolute" as any,
      borderRadius: "50%",
      zIndex: 3,
      marginLeft: "160px",
      marginBottom: "200px",
      display: isUpdateAvailable && isElectron() ? "flex" : "none",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: "2px 2px 4px black",
    },
  };

  return (
    <div className={"item " + className}>
      {/* For showing update badge */}
      {/* <div
        style={styles.updateAvailableBadge}
        onClick={() => {
          if (isElectron()) {
            window["api"].send("updateStory", story.id);
            setUpdateAvailable(false);
          }
        }}
      >
        <img
          style={{ width: "15px", position: "relative" }}
          src={downloadButton}
          alt="Download"
        />
      </div> */}

      <div style={{ margin: "0 40px 0 40px", cursor: "pointer" }}>

        {/* For showing story */}
        <div
          className="card-fill"
          style={styles.cardContainer}
          onClick={() => handleOpenStory()}
        >
          {isElectron() && percentage !== 0 && <div style={{ zIndex: 1 }}>
            <BallClipRotate
              color={"#FFFFFF"}
              loading={true}
            />
          </div>
          }

          <img
            style={styles.downloadButton}
            src={downloadButton}
            onClick={() => handleDownloadStory(story)}
            alt=""
          />



          <div style={styles.imageEffect}></div>
          {!isElectron() && <span style={styles.textStyle}>
            <strong>{percentage}%</strong>
          </span>}

          <div style={styles.progressBar}>
            <CircularProgressbar
              value={percentage}
              strokeWidth={7}
              styles={buildStyles({
                trailColor: "transparent",
                pathTransition: percentage === 0 ? "none" : "",
              })}
            />

          </div>
          <img style={styles.cardImage} src={imageSrc} alt="" draggable={false} />
        </div>

        <div style={styles.starImgContainer}>
          <img id="breathing-button" style={styles.starImg} src={starIcon} alt="" />
        </div>

        <p
          style={{
            textAlign: "center",
            marginTop: "12px",
            paddingTop: "6px",
            paddingBottom: "6px",
            marginRight: "24px",
            marginLeft: "24px",
            fontFamily: `tifinagh0`,
            fontSize: "26px",
            fontWeight: "bolder",
            color: "white",
            backgroundColor: "rgba(0, 0, 0, 0.25)",
            borderRadius: "12px",
          }}
          onClick={() => handleOpenStory()}
        >
          {storyData.story_titles && storyData.story_titles[0] && storyData.story_titles[0].title && storyData.story_titles[0].title}
        </p>
      </div>
    </div>
  );
};

export default Card;
