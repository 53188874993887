/* eslint-disable */
import "../assets/css/settingsScreen.css";
import "../assets/css/scrollable.css";
import isElectron from "is-electron";
import React, { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../hooks/SettingsContext";
import Scrollable from "../ui/components/Scrollable";
import { FontSizes, Layouts } from "../utils/Constants";
import { useHistory } from "react-router-dom";
import PopupDialog from "../ui/components/PopupDialog";
import deleteIcon from "./../assets/images/icons/ic_delete.png";
import homeIcon from "./../assets/images/bar/buttons/home.webp";
import { useTranslation } from "react-i18next";

function HomeSettingsScreen(props) {
  const { setScreen } = props;
  const { lang, setLang, settings, setSettings, playSound, stopSound, isPlaying } =
    useContext(SettingsContext);
  const history = useHistory();

  const [stories, setStories] = useState<any>([]);
  const [playing, setPlaying] = useState(isPlaying());
  // const [autoPlay, setAutoPlay] = useState(settings.autoPlay);
  const [popupShown, showPopup] = useState(false);

  const { t, i18n } = useTranslation();

  const setSoundPlaying = (playing) => {
    let newSettings = { ...settings };
    if (isPlaying()) {
      newSettings.playing = false;
      setPlaying(false);
      stopSound();
    } else {
      newSettings.playing = true;
      setPlaying(true);
      playSound();
    }
    setSettings(newSettings);
  };

  // const setAutoplay = () => {
  //   let newSettings = { ...settings };
  //   if (autoPlay) {
  //     newSettings.autoPlay = false;
  //     setAutoPlay(false);
  //   } else {
  //     newSettings.autoPlay = true;
  //     setAutoPlay(true);
  //   }
  //   setSettings(newSettings);
  // }

  useEffect(() => {
    if (isElectron()) {
      window["api"].send("getLocalStories", null);
      window["api"].receive("localStories", setStories);
    }
  }, []);

  const resetSettings = () => {
    let newSettings = { fontType: 0, fontSize: FontSizes.FONT_MEDIUM, playing: true };
    setPlaying(true);
    setSettings(newSettings);
    if (!isPlaying()) playSound();
    localStorage.clear();
    showPopup(false);
  };

  // if (!isElectron()) {
  //   window.onpopstate = (e) => history.push({ pathname: "/home" });
  // }

  return (
    <div dir={lang == "ar" ? "rtl" : "ltr"} className="general-container font-tifinagh-1">
      {popupShown && (
        <PopupDialog
          body={<p style={{ color: "white", fontSize: "22px" }}>{t("reset_params_msg")}</p>}
          onValidate={() => resetSettings()}
          onCancel={() => showPopup(false)}
          showDialog={popupShown}
        />
      )}
      <div className="settings-header">
        {!isElectron() ? (
          <img
            onClick={() => setScreen(Layouts.HOME)}
            src={homeIcon}
            style={{ cursor: "pointer", width: "48px", height: "48px" }}
            alt=""
          />
        ) : (
          <img
            onClick={() => {
              history.push({
                pathname: "/home",
              });
            }}
            src={homeIcon}
            style={{ cursor: "pointer", width: "48px", height: "48px" }}
            alt=""
          />
        )}
        <h1>{t("settings")}</h1>
      </div>
      <div className="settings-container">
        <div className="font-settings">
          <div className="radio-options">
            <h3 className="check-options-h3">{t("language")}</h3>
            <div className="langue" style={{ marginLeft: "8px", marginRight: "8px" }}>
              <input
                type="radio"
                id="font-3"
                name="font-type"
                checked={lang === "am"}
                onChange={() => {
                  i18n.changeLanguage("am").then(() => {
                    setLang("am");
                  });
                }}
              />
              <label htmlFor="font-3">ⵜⴰⵎⴰⵣⵉⵖⵜ</label>
            </div>
            <div className="langue" style={{ marginLeft: "8px", marginRight: "8px" }}>
              <input
                type="radio"
                id="font-4"
                name="font-type"
                checked={lang === "en"}
                onChange={() => {
                  i18n.changeLanguage("en").then(() => {
                    setLang("en");
                  });
                }}
              />
              <label htmlFor="font-4">English</label>
            </div>
            <div className="langue" style={{ marginLeft: "8px", marginRight: "8px" }}>
              <input
                type="radio"
                id="font-1"
                name="font-type"
                checked={lang === "fr"}
                onChange={() => {
                  i18n.changeLanguage("fr").then(() => {
                    setLang("fr");
                  });
                }}
              />
              <label htmlFor="font-1">Français</label>
            </div>
            <div className="langue-ar" style={{ marginLeft: "8px", marginRight: "8px" }}>
              <input
                type="radio"
                id="font-2"
                name="font-type"
                checked={lang === "ar"}
                onChange={() => {
                  // const nextLang = i18n.language === 'ar' ? 'fr' : 'ar';
                  i18n.changeLanguage("ar").then(() => {
                    setLang("ar");
                  });
                }}
              />
              <label htmlFor="font-2">العربية</label>
            </div>
          </div>

          {/* Background music */}
          <div className="check-options">
            <h3 className="check-options-h3">{t("background_music")}</h3>
            <label className="switch">
              <input
                type="checkbox"
                checked={playing}
                onChange={(event) => setSoundPlaying(event.target.checked)}
              />
              <span className="slider round"></span>
            </label>
          </div>

          <hr
            style={{
              margin: "28px 8px 24px 8px",
              border: "1px solid #eee",
            }}
          />

          {/* Autoplay */}
          {/* <div className="check-options">
            <h3 className="check-options-h3">Autoplay</h3>
            <label className="switch">
              <input
                type="checkbox"
                checked={autoPlay}
                onChange={(event) => setAutoplay()}
              />
              <span className="slider round"></span>
            </label>
          </div> */}

          {/* <hr
            style={{
              margin: "28px 8px 24px 8px",
              border: "1px solid #eee",
            }}
          /> */}

          {/* Reinitialize parameters */}
          <div className="check-options">
            {/* <h3 className="check-options-h3">{t("reset_settings")}</h3 */}
            <a className="check-options-a" onClick={() => showPopup(true)}>
              {t("reset_settings")}
            </a>
          </div>
        </div>
      </div>

      {isElectron() && stories.map && (
        <div>
          <hr style={{ margin: "0px 8px 12px 8px", border: "1px solid #eee" }} />
          <div className="check-options" style={{ margin: "16px", fontSize: "18px" }}>
            <h3 className="check-options-h3" style={{ minWidth: "24vw" }}>
              {t("my_tales")}
            </h3>
            <Scrollable skipCount={true}>
              {stories.map((card, index) => (
                <StoryThumbnail
                  className={"item" + index}

                  key={index}
                  story={card}
                  currentStoryId={card.id}
                  history={history}
                />
              ))}
            </Scrollable>
          </div>
        </div>
      )}
    </div>
  );
}

const StoryThumbnail = (props) => {
  const { className, story, currentStoryId, history } = props;

  return (
    <div
      className={"item " + className}
      style={{
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "16px",
      }}
    >
      <img
        src={story.coverImage}
        style={{
          height: "10rem",
          width: "10rem",
          borderRadius: "5rem",
          boxShadow: "0px 0px 8px black",
        }}
      />

      <label
        style={{
          position: "absolute",
          cursor: "pointer",
          color: "red",
          fontWeight: "bold",
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          backgroundColor: "#00000040",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          if (window.confirm("Voulez-vous vraiment supprimer ce conte?")) {
            window["api"].send("deleteStory", story.id);
            window["api"].receive("storyDeleted", (data) => {
              if (data === "Done" && story.id === currentStoryId)
                history.push({ pathname: "/home" });
            });
          }
        }}
      >
        <img src={deleteIcon} style={{ height: "2rem" }} />
      </label>
    </div>
  );
};

export default HomeSettingsScreen;
